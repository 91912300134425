import { useNetworkStatus } from "../utils/hooks/networkStatus";
import { HttpStatusCode } from "axios";
import { useEffect, useState } from "react";
import {
  createHashRouter,
  createRoutesFromElements,
  generatePath,
  Navigate,
  NavigateOptions,
  Outlet,
  Route,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { api } from "../API/axios";
import { Layout } from "../components/layout";
import { useAuthentication } from "../context/useAuthenticationContext";
import {
  AddAtticFloor,
  AddDormer,
  AddFloorElement,
  AddRoofElement,
  AddWallElement,
  AddWindow,
  Dashboard,
  Flat,
  Flooring,
  HouseInfo,
  HouseInspection,
  Houses,
  InspectionComplete,
  Login,
  Roof,
  Solar,
  Wall,
} from "../pages";
import { AddSolarArray } from "../pages/Solar/AddSolarArray";
import Providers from "../providers";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getDBInspection, getProductsIndexDB } from "../store/indexedDB/objectStore";
import {
  getImageTagsLoadingStatus,
  getSuggestionsLoadingStatus,
  syncProducts,
  syncStateWithIndexDB,
} from "../store/slices";
import { fetchImageTags, fetchProducts, fetchSuggestions } from "../store/thunks";
import { Loading } from "../types";
import localStorage from "../utils/localStorage";
import { useLocation } from "react-router-dom";
import { AppVersion } from "../components/common/AppVersion";
import { GlazingNotes } from "../pages/Flat/GlazingNotes";
import { Summary } from "../pages/Summary/Summary";

export enum Path {
  Login = "*",
  Switcher = "/switcher",
  SignAndSubmit = "/summary-page",
  InspectionComplete = "/inspection-complete",
  Houses = "/houses",
  HouseInspection = "/houses/:id",
  /* nesting other routes under HouseInspection */
  Dashboard = "dashboard",
  HouseInfo = "details",
  Roof = "roof",
  Wall = "wall",
  Flat = "flat",
  Floor = "floor",
  Solar = "solar",
  AddWallElement = "add-wall-element/:wallId?",
  AddRoofElement = "add-roof-element/:roofId?",
  AddDormer = ":roofId?/add-dormer/:dormerId?",
  AddAtticFloor = ":roofId?/add-attic-floor",
  AddWindowRoof = "/houses/:id/flat/:flatId?/add-window/roof/:roofId",
  AddWindowWall = "/houses/:id/flat/:flatId?/add-window/wall/:wallId",
  AddWindowDormer = "/houses/:id/flat/:flatId?/add-window/dormer/:dormerId",
  AddFloorElement = "add-floor-element/:floorId?",
  AddSolarArray = "add-solar-element/:solarId?",
  AddWindowGroup = "/houses/:id/flat/:flatId?/add-window/:storeyId?",
  AddWindowGroupNotes = "/houses/:id/storey/:flatId?/:storeyId?/notes?",
}

const MainApp = () => {
  const [isSidebarHidden, setSidebarHidden] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const suggestionsLoadingStatus = useAppSelector(getSuggestionsLoadingStatus);
  const imageTagsLoadingStatus = useAppSelector(getImageTagsLoadingStatus);

  useEffect(() => {
    if (suggestionsLoadingStatus === Loading.IDLE) dispatch(fetchSuggestions());
    if (imageTagsLoadingStatus === Loading.IDLE) dispatch(fetchImageTags());
  }, [dispatch, suggestionsLoadingStatus, imageTagsLoadingStatus]);

  useEffect(() => {
    const pathParts = pathname.split("/");
    const isSidebarHidden = pathParts.length > 4;

    setSidebarHidden(isSidebarHidden);
  }, [pathname]);

  return (
    <Layout isSidebarHidden={isSidebarHidden}>
      <Outlet />
    </Layout>
  );
};

const ProtectedRoutes = () => {
  const isOnline = useNetworkStatus();
  const { userToken } = useAuthentication();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(true);
  const { signIn } = useAuthentication();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!userToken) {
      const token = localStorage.getToken();
      const username = localStorage.getUsername();
      if (token && username) {
        // send request to the backend and validate the token. If token is not valid navigate to login page
        (async function auth(username: string, token: string) {
          try {
            setIsLoading(true);
            const res = await api.post("/authCheck", { username, token });
            if (res.status === HttpStatusCode.Ok) {
              signIn(username, token);

              setIsUserAuthenticated(true);
              setIsLoading(false);
            } else {
              throw new Error();
            }
          } catch (error) {
            setIsUserAuthenticated(false);
            setIsLoading(false);
          }
        })(username, token);
      } else {
        setIsLoading(false);
        setIsUserAuthenticated(false);
      }
    } else {
      setIsUserAuthenticated(true);
    }
  }, [navigate, isUserAuthenticated, userToken, signIn]);

  useEffect(() => {
    // sync redux state with index DB if user is authenticated
    if (!isLoading && isUserAuthenticated) {
      const sphId = localStorage.getSphId();
      (async () => {
        const inspection = await getDBInspection(Number(sphId));
        const products = await getProductsIndexDB();
        dispatch(syncStateWithIndexDB(inspection));
        if (!products.length && sphId) dispatch(fetchProducts(+sphId));
        else if (products) dispatch(syncProducts(products[0]));
      })();
    }
  }, [dispatch, isLoading, isUserAuthenticated]);

  return (
    <>
      {!isUserAuthenticated && !isLoading && !localStorage.getToken() && (
        <Navigate to={Path.Login} />
      )}
      {isLoading && <>Loading...</>}
      {(!isLoading && isUserAuthenticated && (
        <>
          <Outlet />
          <AppVersion />
        </>
      )) ||
        (localStorage.getToken() && !isOnline && (
          <>
            <Outlet />
            <AppVersion />
          </>
        ))}
    </>
  );
};

const routes = createRoutesFromElements(
  <Route>
    <Route index path={Path.Login} element={<Login />} />
    <Route element={<Providers />}>
      <Route element={<ProtectedRoutes />}>
        <Route path={Path.InspectionComplete} element={<InspectionComplete />} />
        <Route path={Path.SignAndSubmit} element={<Summary />} />
        <Route path={Path.Houses} element={<Houses />} />
        {/* wrap everything with HouseInspection path - parent route */}
        <Route path={Path.HouseInspection}>
          <Route index element={<HouseInspection />} />
          <Route element={<MainApp />}>
            <Route path={Path.HouseInfo} element={<HouseInfo />} />
            <Route path={Path.Dashboard} element={<Dashboard />} />
            <Route path={Path.Roof}>
              <Route index element={<Roof />} />
              <Route path={Path.AddRoofElement} element={<AddRoofElement />} />
              <Route path={Path.AddDormer} element={<AddDormer />} />
            </Route>
            <Route path={Path.Flat} element={<Flat />} />
            <Route path={Path.Wall}>
              <Route index element={<Wall />} />
              <Route path={Path.AddWallElement} element={<AddWallElement />} />
            </Route>
            <Route path={Path.Solar} element={<Solar />} />
            <Route path={Path.Floor}>
              <Route index element={<Flooring />} />
              <Route path={Path.AddFloorElement} element={<AddFloorElement />} />{" "}
            </Route>
            <Route path={Path.Solar}>
              <Route index element={<Solar />} />
              <Route path={Path.AddSolarArray} element={<AddSolarArray />} />
            </Route>
            {/* all routes that have add should have path like this: /houses/3/walls/:name_or_:id/add-wall/:name_or_:id/add ? */}
            <Route path={Path.AddWindowGroup} element={<AddWindow />} />
            <Route path={Path.AddWindowGroupNotes} element={<GlazingNotes />} />
            <Route path={Path.AddAtticFloor} element={<AddAtticFloor />} />
            <Route path={Path.AddWindowWall} element={<AddWindow />} />
            <Route path={Path.AddWindowRoof} element={<AddWindow />} />
            <Route path={Path.AddWindowDormer} element={<AddWindow />} />
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>,
);

export const router = createHashRouter(routes, {
  basename: process.env.REACT_APP_SUBDIRECTORY_PATH,
});

export const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

type PathParams = {
  id?: string;
  roofId?: string;
  dormerId?: string;
  wallId?: string;
  floorId?: string;
  solarId?: string;
  flatId?: string;
  storeyId?: string;
  notesId?: string;
  "*"?: string;
};

export function useNavigation() {
  const navigate = useNavigate();

  return function redirect<S extends string>(
    path: S,
    params?: PathParams,
    options?: NavigateOptions,
  ) {
    const fullPath = generatePath<S>(path, params as any);
    navigate({ pathname: fullPath }, options);
  };
}
