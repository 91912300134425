import { yupResolver } from "@hookform/resolvers/yup";
import EditIcon from "@mui/icons-material/Edit";
import { Box, InputAdornment } from "@mui/material";
import { t } from "i18next";
import { ChangeEvent, FC, useEffect, useState, memo, useMemo } from "react";
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, useNavigate, useParams } from "react-router-dom";
import { AccordionContainer } from "../../components/common/Accordion/Accordion";
import { MainButton } from "../../components/common/Button/MainButton";
import { Card } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { TwoColumnsContainer } from "../../components/common/Container/TwoColumnsContainer";
import { Cover } from "../../components/common/Cover/Cover";
import { FormInput } from "../../components/common/Form/FormInput";
import { FormSelect } from "../../components/common/Form/FormSelect";
import { FormStack } from "../../components/common/Form/FormStack";
import {
  AdditionalData,
  AdditionalDataWrapper,
} from "../../components/common/StepComponents/AdditionalData";
import { SectionTitle } from "../../components/common/StepComponents/StepContainers";
import { FloorAreas } from "../../components/common/SurfaceArea";
import { Photos } from "../../components/photos/Photos";
import { Path } from "../../routes/index";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getFilteredImageTags, getFilteredSuggestions } from "../../store/slices";
import {
  getHouseInfoData,
  getInspectionDataLoadingStatus,
  saveHouseInfo,
  setStoreyAreas,
  setStoreyCount,
  setInitialWindowsPreferences,
  updateStoreyArea,
} from "../../store/slices/inspectionSlice";
import {
  BoilerType,
  BuildingType,
  Category,
  HeatingTemperatureClass,
  HeatRecoveryInstallationType,
  SuggestionCategory,
  VentilationType,
} from "../../types";
import { getHouseInfoSchema } from "../../types/schema";
import { toast } from "react-toastify";
import stringToNumber from "../../utils/helpers/strToNum";

export interface IFormContext {
  building: {
    storeyCount: number;
    constructionYear: number;
    buildingType?: BuildingType;
    ridgeHeight: number;
    ventilationType?: VentilationType;
    storeyAreas: number[];
    heatRecoveryInstallationType?: HeatRecoveryInstallationType;
    heatingTemperatureClass?: HeatingTemperatureClass;
    boilerType?: BoilerType;
    errors: string[];
  };
  floorAreas: {
    width?: number | undefined;
    height?: number | undefined;
    area: number;
  }[];
  accessibility: string;
}

const AreaContainer: FC<{ index: number; handleSave: () => Promise<void> }> = ({
  index,
  handleSave,
}) => {
  const form = useFormContext<IFormContext>();
  const { t } = useTranslation();
  const [showSurface, setShowSurface] = useState(false);

  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Box>
      <FormInput
        {...register(`floorAreas.${index}.area`, { valueAsNumber: true })}
        label={t("surfaceArea")}
        error={errors.floorAreas && errors.floorAreas[index]?.area}
        InputLabelProps={{ shrink: true }}
        onBlur={handleSave}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <MainButton
                variant="white"
                text={t("edit")}
                icon={<EditIcon fontSize="small" sx={{ display: "flex" }} />}
                onClick={() => setShowSurface(!showSurface)}
              />
            </InputAdornment>
          ),
        }}
      />
      {showSurface && <FloorAreas index={index} />}
    </Box>
  );
};

const SingleFloorArea: FC<{
  floorName: string;
  index: number;
}> = ({ floorName, index }) => {
  const form = useFormContext<IFormContext>();

  const [expanded, setExpanded] = useState<string | false>(false);
  const isExpanded = (panel: string) => expanded === panel;
  const handleChange = (panel: string) => setExpanded(isExpanded(panel) ? false : panel);

  const dispatch = useAppDispatch();
  const {
    formState: { errors },
    trigger,
    getValues,
  } = form;

  useEffect(() => {
    trigger();
  }, [trigger]);

  const handleSaveStoreyArea = async () => {
    await trigger();
    const { area } = getValues("floorAreas")[index];
    dispatch(updateStoreyArea({ area, index }));
    setExpanded(false);
  };
  return (
    <AccordionContainer
      title={floorName}
      panel={floorName}
      expanded={isExpanded(floorName)}
      errors={
        errors.floorAreas?.[index]?.area?.message
          ? [errors.floorAreas?.[index]?.area?.message as string]
          : []
      }
      handleChange={handleChange}
    >
      <Card sx={{ backgroundColor: "#F8F8F8", height: "100%" }}>
        <TwoColumnsContainer
          sx={{ mt: 1, mb: 0 }}
          childrenFirstColumn={<AreaContainer index={index} handleSave={handleSaveStoreyArea} />}
          childrenSecondColumn={undefined}
        />
      </Card>
    </AccordionContainer>
  );
};

const NumberOfStoreys: FC<any> = memo(({ handleFloorAreas }) => {
  const form = useFormContext<IFormContext>();
  const {
    register,
    formState: { errors },
  } = form;
  return (
    <FormStack>
      <FormInput
        label={t("numberOfFloors")}
        {...register("building.storeyCount", {
          valueAsNumber: true,
        })}
        error={errors.building?.storeyCount}
        type="number"
        onBlur={(event) => handleFloorAreas(event)}
      />
    </FormStack>
  );
});

const LeftColumn: FC<any> = () => {
  const form = useFormContext<IFormContext>();
  const {
    register,
    formState: { errors },
  } = form;
  return (
    <FormStack>
      <FormSelect
        form={form}
        options={Object.values(BuildingType)}
        label={t("houseType")}
        name={"building.buildingType"}
        error={errors.building?.buildingType}
      />

      <FormInput
        label={t("constructionYear")}
        {...register("building.constructionYear", { valueAsNumber: true })}
        error={errors.building?.constructionYear}
      />

      <FormSelect
        form={form}
        options={Object.values(BoilerType)}
        label={t("boilerType")}
        name={"building.boilerType"}
        error={errors.building?.boilerType}
      />
    </FormStack>
  );
};

const RightColumn: FC = memo(() => {
  const form = useFormContext<IFormContext>();
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <FormStack>
      <FormSelect
        form={form}
        options={Object.values(HeatingTemperatureClass)}
        label={t("heatingTemperatureClass")}
        name={"building.heatingTemperatureClass"}
        error={errors.building?.heatingTemperatureClass}
      />

      <FormSelect
        form={form}
        options={Object.values(HeatRecoveryInstallationType)}
        label={t("heatRecoveryInstallationType")}
        name={"building.heatRecoveryInstallationType"}
        error={errors.building?.heatRecoveryInstallationType}
      />

      <FormInput
        label={t("ridgeHeight")}
        {...register("building.ridgeHeight", {
          setValueAs: (v) => stringToNumber(v),
        })}
        error={errors.building?.ridgeHeight}
      />

      <FormSelect
        form={form}
        options={Object.values(VentilationType)}
        label={t("ventilationType")}
        name={"building.ventilationType"}
        error={errors.building?.ventilationType}
      />
    </FormStack>
  );
});

export const HouseInfo = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const inspectionLoading = useAppSelector(getInspectionDataLoadingStatus);
  const houseInfoData = useAppSelector(getHouseInfoData);

  const defaultHouseInfoData = useMemo(() => {
    return {
      images: houseInfoData?.images || [],
      floorAreas: houseInfoData?.floorAreas,
      building: {
        ...houseInfoData?.building,
        storeyCount: houseInfoData?.building?.storeyCount || 1,
      },
      accessibility: houseInfoData?.accessibility,
    };
  }, [houseInfoData]);

  const form = useForm({
    resolver: yupResolver(getHouseInfoSchema(t)),
    mode: "all",
    shouldUnregister: true,
    defaultValues: defaultHouseInfoData,
  });
  const { trigger, reset } = form;
  const { fields, append, remove, replace } = useFieldArray({
    control: form.control,
    name: "floorAreas",
  });
  const handleFloorAreas = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value.match(/0\d+/g)) return;
    if (+event.target.value > 21) return;

    if (!event.target.value || +event.target.value <= 0) {
      dispatch(setStoreyAreas({ storeyAreas: [0] }));
      dispatch(setStoreyCount(1));
      replace([]);
      return;
    }

    const inputValue = +event.target.value;
    dispatch(setStoreyCount(inputValue));
    const currentFloorAreas = houseInfoData.floorAreas.length;

    const groundFloor = {
      storey: inputValue,
      storeyName: "Began woonlaag",
      area: 0,
      width: 0,
      height: 0,
    };

    const newFloorArea = {
      storey: inputValue,
      storeyName: `${inputValue - 1}e woonlaag`,
      area: 0,
      width: 0,
      height: 0,
    };

    if (inputValue > currentFloorAreas && inputValue === 1) {
      houseInfoData.floorAreas.push(groundFloor);
      append(groundFloor);
      dispatch(setStoreyAreas({ storeyAreas: [0] }));
      return;
    }

    if (inputValue > currentFloorAreas && inputValue - currentFloorAreas === 1) {
      houseInfoData.floorAreas.push(newFloorArea);
      append(newFloorArea);
      dispatch(setStoreyAreas({ storeyAreas: [...houseInfoData.building.storeyAreas, 0] }));
    } else if (inputValue > currentFloorAreas && inputValue - currentFloorAreas > 1) {
      let counter = 0;
      for (let i = currentFloorAreas; i < inputValue; i++) {
        counter++;
        if (i === 0) {
          houseInfoData.floorAreas.push(groundFloor);
          append(groundFloor);
        } else {
          houseInfoData.floorAreas.push({
            ...newFloorArea,
            storey: i,
            storeyName: `${i}e woonlag`,
          });
          append({ ...newFloorArea, storey: i, storeyName: `${i}e woonlag` });
        }
      }

      const defaultStoreyAreas = new Array<number>(counter).fill(0);
      dispatch(
        setStoreyAreas({
          storeyAreas: [...houseInfoData.building.storeyAreas, ...defaultStoreyAreas],
        }),
      );
    } else if (currentFloorAreas > inputValue && currentFloorAreas - inputValue === 1) {
      houseInfoData.floorAreas.splice(inputValue);
      remove(inputValue);
      const storeyAreas = houseInfoData?.floorAreas?.map((el) => el.area);
      dispatch(setStoreyAreas({ storeyAreas }));
    } else if (currentFloorAreas > inputValue && currentFloorAreas - inputValue > 1) {
      houseInfoData.floorAreas.splice(inputValue);
      for (let i = currentFloorAreas; i >= inputValue; i--) {
        remove(i);
      }
      const storeyAreas = houseInfoData?.floorAreas?.map((el) => el.area);
      dispatch(setStoreyAreas({ storeyAreas }));
    }
    await form.trigger();
  };
  const tags = useAppSelector(getFilteredImageTags(Category.INSPECTION));

  const houseSuggestions = useAppSelector(getFilteredSuggestions(SuggestionCategory.ACCESSIBILITY));

  const handleSave = async () => {
    if ("building" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("building.constructionYear");
      return;
    }

    if ("accessibility" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("accessibility");
      return;
    }

    const storeyCount = form.getValues("building.storeyCount");
    dispatch(setInitialWindowsPreferences({ storeyCount }));
    dispatch(saveHouseInfo({ ...form.getValues(), errors: Object.keys(form.formState.errors) }));

    toast.success(t("success"));
    navigate(`../${Path.Wall}`);
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    reset({
      building: {
        buildingType: houseInfoData.building?.buildingType,
        ridgeHeight: houseInfoData.building?.ridgeHeight,
        boilerType: houseInfoData.building?.boilerType,
        constructionYear: houseInfoData.building?.constructionYear || 0,
        ventilationType: houseInfoData.building?.ventilationType,
        heatingTemperatureClass: houseInfoData.building?.heatingTemperatureClass,
        heatRecoveryInstallationType: houseInfoData.building?.heatRecoveryInstallationType,
        storeyCount: houseInfoData.building?.storeyCount,
        storeyAreas: houseInfoData.building?.storeyAreas,
      },
      floorAreas: houseInfoData?.floorAreas,
      accessibility: houseInfoData?.accessibility || "",
      images: houseInfoData?.images || [],
    });
  }, [inspectionLoading, houseInfoData.accessibility, houseInfoData.floorAreas, reset]);
  return (
    <MainPageWrapper>
      <Cover title={t("generalInfo")} />
      <PageWrapper>
        <Box>
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(handleSave, handleSave)}>
              <Card>
                <SectionTitle title={t("floorAreas")} sx={{ mb: 4 }} />
                <NumberOfStoreys handleFloorAreas={handleFloorAreas} />
                {fields &&
                  fields.map((field, index) => (
                    <SingleFloorArea
                      key={field.id}
                      index={index}
                      floorName={`${houseInfoData?.floorAreas[index]?.storeyName}`}
                    />
                  ))}
              </Card>
              <Card>
                <SectionTitle title={t("generalHouseInfo")} sx={{ mb: 2 }}></SectionTitle>
                <TwoColumnsContainer
                  childrenFirstColumn={<LeftColumn handleFloorAreas={handleFloorAreas} />}
                  childrenSecondColumn={<RightColumn />}
                />
              </Card>
              <Photos sphId={id || ""} tags={tags} form={form} />

              <AdditionalDataWrapper title={t("accessibility")}>
                <AdditionalData
                  name="accessibility"
                  form={form}
                  suggestions={houseSuggestions}
                  error={form.formState.errors.accessibility}
                />
              </AdditionalDataWrapper>

              <MainButton
                text={t("save")}
                variant="primary"
                type="submit"
                sx={{ margin: "30px 0" }}
              />
            </Form>
          </FormProvider>
        </Box>
      </PageWrapper>
    </MainPageWrapper>
  );
};
