const storageUsername = `${process.env.REACT_APP_LOCAL_STORAGE_INSPECTION_APP}_username`;
const storageToken = `${process.env.REACT_APP_LOCAL_STORAGE_INSPECTION_APP}_token`;
const storageSphId = `${process.env.REACT_APP_LOCAL_STORAGE_INSPECTION_APP}_sph_id`;

const localStorage = {
  getUsername: () => {
    return window.localStorage.getItem(storageUsername) as string;
  },
  getToken: () => {
    return window.localStorage.getItem(storageToken) as string;
  },
  setToken: (username: string, token: string) => {
    window.localStorage.setItem(storageUsername, username);
    window.localStorage.setItem(storageToken, token);
  },
  clearToken: () => {
    window.localStorage.removeItem(storageUsername);
    window.localStorage.removeItem(storageToken);
  },
  setSphId: (sphId: string) => {
    window.localStorage.setItem(storageSphId, sphId);
  },
  getSphId: () => {
    return window.localStorage.getItem(storageSphId) as string;
  },
};

export default localStorage;
