import { createSelector, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import {
  Category,
  Direction,
  IDormer,
  IFloor,
  IInspection,
  IProduct,
  IRoof,
  ISolarPanel,
  IWall,
  IWindow,
  IWindowGroup,
  Loading,
  SummaryData,
  SummaryElements,
  typedEntries,
} from "../../types";
import { updateIndexedDB } from "../indexedDB/objectStore";
import { RootState } from "../store";
import { fetchInspection } from "../thunks";
import { SummarySchemaType } from "src/pages/Summary/SummaryCard";

const initialState: { data: IInspection; loading: string; error: string } = {
  data: {} as IInspection,
  loading: "",
  error: "",
};

export const inspectionSlice = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    saveRoof: (state, action) => {
      const { ...newRoof } = action.payload;
      if (action.payload?.measure?.insulationProduct?.name === "geen") {
        action.payload.measure.insulationProduct = null;
        action.payload.measure.insulationThickness = 0;
      }
      state.data.roofs.push(newRoof);
      updateIndexedDB(current(state.data));
    },
    deleteRoof: (state, action) => {
      state.data.roofs = state.data.roofs.filter((roof) => roof.name !== action.payload.roofId);
      updateIndexedDB(current(state.data));
    },
    saveSolarPanel: (state, action) => {
      const { solarId } = action.payload;
      const solarPanel = state.data.solar?.measures.findIndex((solar) => solar.name === solarId);
      delete action.payload.solarId;
      let solarProduct = null;
      if (action.payload.panelType !== "geen") {
        solarProduct = {
          name: action.payload.panelType,
          id: action.payload.panelTypeId,
        };
      }
      /* remove panelType prop */
      delete action.payload.panelType;
      delete action.payload.panelTypeId;
      if (solarPanel > -1) {
        state.data.solar.measures[solarPanel] = { ...action.payload, solarProduct };
      } else {
        state.data.solar.measures.push({ ...action.payload, solarProduct });
      }
      updateIndexedDB(current(state.data));
    },
    saveSolarPage: (state, action) => {
      state.data.notes = {
        ...state.data.notes,
        ownerSolar: action.payload.ownerSolar,
        supplierSolar: action.payload.supplierSolar,
      };
      state.data.solar = {
        ...state.data.solar,
        images: action.payload.images,
        currentPhaseConnection: action.payload.currentPhaseConnection,
        requiredPhaseConnection: action.payload.requiredPhaseConnection,
      };
      updateIndexedDB(current(state.data));
    },
    deleteSolar: (state: any, action) => {
      state.data.solar.measures = state.data.solar.measures?.filter(
        (solar: any) => solar.name !== action.payload.solarId,
      );
      updateIndexedDB(current(state.data));
    },
    saveDormer: (state, action) => {
      const { roofId, ...newDormer } = action.payload;
      state.data.roofs.find((roof) => roof.name === newDormer.roofName)?.dormers.push(newDormer);
      updateIndexedDB(current(state.data));
    },
    saveAdditionalInspectionData: (state, action) => {
      const {
        isScaffoldingRequired,
        wallDrillDiameter,
        wallCavityBrushCount,
        ownerWall,
        supplierWall,
      } = action.payload;
      state.data.isScaffoldingRequired = isScaffoldingRequired;
      state.data.wallCavityBrushCount = wallCavityBrushCount;
      state.data.wallDrillDiameter = wallDrillDiameter;
      state.data.notes.ownerWall = ownerWall;
      state.data.notes.supplierWall = supplierWall;
      updateIndexedDB(current(state.data));
    },
    saveAtticFloor: (state, action) => {
      const { ...atticFloor } = action.payload;
      if (action.payload?.measure?.insulationProduct?.name === "geen") {
        action.payload.measure.insulationProduct = null;
        action.payload.measure.insulationThickness = 0;
      }
      state.data.atticFloor = atticFloor;
      updateIndexedDB(current(state.data));
    },
    deleteAtticFloor: (state) => {
      state.data.atticFloor = null;
      updateIndexedDB(current(state.data));
    },
    saveNotes: (state, action) => {
      state.data.notes = {
        ...state.data.notes,
        ...action.payload,
      };
      updateIndexedDB(current(state.data));
    },
    saveWindowNotes: (state, action) => {
      const { index, ownerWindow, supplierWindow } = action.payload;
      state.data.notes.ownerWindows[index] = ownerWindow;
      state.data.notes.supplierWindows[index] = supplierWindow;
    },
    saveWall: (state, action) => {
      const { wallId } = action.payload;
      const wall = state.data.walls.findIndex((wall) => wall.name === wallId);
      delete action.payload.wallId;
      if (action.payload?.measure?.insulationProduct?.name === "geen") {
        action.payload.measure.insulationProduct = null;
        action.payload.measure.insulationThickness = 0;
      }
      if (wall > -1) {
        state.data.walls[wall] = action.payload;
      } else {
        state.data.walls.push(action.payload);
      }
      updateIndexedDB(current(state.data));
    },
    deleteWall: (state, action) => {
      state.data.walls = state.data.walls.filter((wall) => wall.name !== action.payload.wallId);
      updateIndexedDB(current(state.data));
    },
    updateFloorArea: (state, action) => {
      const { area, index } = action.payload;
      state.data.building.storeyAreas[index] = area;

      const floor = state.data.floors.find((_floor, idx) => idx === index);
      if (floor) {
        floor.area = area;
      }
      updateIndexedDB(current(state.data));
    },
    deleteFloorArea: (state, action) => {
      const { index } = action.payload;
      if (state.data.floors[index]) {
        state.data.floors.splice(index, 1);
      }
      updateIndexedDB(current(state.data));
    },
    saveHouseInfo: (state, action) => {
      const {
        constructionYear,
        buildingType,
        ridgeHeight,
        ventilationType,
        boilerType,
        heatingTemperatureClass,
        heatRecoveryInstallationType,
      } = action.payload.building;
      const { errors, images } = action.payload;

      const { accessibility } = action.payload;
      state.data.building.constructionYear = constructionYear;
      state.data.building.buildingType = buildingType;
      state.data.building.ridgeHeight = ridgeHeight;
      state.data.building.ventilationType = ventilationType;
      state.data.building.boilerType = boilerType;
      state.data.building.heatingTemperatureClass = heatingTemperatureClass;
      state.data.building.heatRecoveryInstallationType = heatRecoveryInstallationType;
      state.data.building.errors = errors;
      state.data.notes.internal = accessibility;
      state.data.images = images;
      updateIndexedDB(current(state.data));
    },
    saveFloorData: (state, action) => {
      state.data.notes = {
        ...state.data.notes,
        ownerFloor: action.payload.ownerFloor,
        supplierFloor: action.payload.supplierFloor,
      };
      state.data.hoseDistance = action.payload.hoseDistance;
      updateIndexedDB(current(state.data));
    },
    saveFloorElement: (state, action) => {
      const { initialFloorName, ...newFloor } = action.payload;
      if (action.payload?.measure?.floorInsulationProduct?.name === "geen") {
        action.payload.measure.floorInsulationProduct = null;
        action.payload.measure.floorInsulationThickness = 0;
      }
      if (action.payload?.measure?.soilInsulationProduct?.name === "geen") {
        action.payload.measure.soilInsulationProduct = null;
        action.payload.measure.soilWallSeparationLength = 0;
        action.payload.measure.soilInsulationThickness = 0;
      }
      state.data.floors.push(newFloor);
      updateIndexedDB(current(state.data));
    },
    deleteFloorElement: (state, action) => {
      const { floorId } = action.payload;
      state.data.floors = state.data.floors.filter((floor) => floor.name !== floorId);
      updateIndexedDB(current(state.data));
    },
    editFloorElement: (state, action) => {
      const { editId, floorId, ...newFloor } = action.payload;
      if (action.payload?.measure?.floorInsulationProduct?.name === "geen") {
        action.payload.measure.floorInsulationProduct = null;
        action.payload.measure.floorInsulationThickness = 0;
      }
      if (action.payload?.measure?.soilInsulationProduct?.name === "geen") {
        action.payload.measure.soilInsulationProduct = null;
        action.payload.measure.soilWallSeparationLength = 0;
        action.payload.measure.soilInsulationThickness = 0;
      }
      if (editId) {
        state.data.floors = state.data.floors.filter((roof) => roof.name !== editId);
      } else {
        state.data.floors = state.data.floors.filter((roof) => roof.name !== floorId);
      }
      state.data.floors.push(newFloor);
      updateIndexedDB(current(state.data));
    },
    editRoofElement: (state, action) => {
      const { editId, roofId, ...newRoof } = action.payload;
      if (action.payload?.measure?.insulationProduct?.name === "geen") {
        action.payload.measure.insulationProduct = null;
        action.payload.measure.insulationThickness = 0;
      }
      if (editId) {
        state.data.roofs = state.data.roofs.filter((roof) => roof.name !== editId);
      } else {
        state.data.roofs = state.data.roofs.filter((roof) => roof.name !== roofId);
      }
      state.data.roofs.push(newRoof);
      updateIndexedDB(current(state.data));
    },
    editDormer: (state, action) => {
      const { roofId, dormerId, editId, ...newDormer } = action.payload;

      if (editId) {
        state.data.roofs?.forEach((roof) => {
          roof.dormers = roof.dormers.filter((dormer) => dormer.name !== editId);
        });
      } else {
        state.data.roofs?.forEach((roof) => {
          roof.dormers = roof.dormers.filter((dormer) => dormer.name !== dormerId);
        });
      }

      state.data.roofs?.find((roof) => roof.name === newDormer.roofName)?.dormers.push(newDormer);
      updateIndexedDB(current(state.data));
    },
    deleteDormer: (state, action) => {
      const { dormerId } = action.payload;

      const dormer = state.data.roofs.map((roof) =>
        roof.dormers.findIndex((dormer) => dormer.name === dormerId),
      );
      if (dormer[0] > -1) {
        state.data.roofs.map((roof) => roof.dormers.splice(dormer[0], 1));
        updateIndexedDB(current(state.data));
      }
    },
    addWindowGroup: (state, action) => {
      const { wallId, roofId, dormerId, parentElement, ...newWindowGroup } = action.payload;
      let parsedWallId: string | undefined = undefined;
      let parsedRoofId: string | undefined = undefined;
      let parsedDormerId: string | undefined = undefined;
      let element = "";
      if (parentElement.includes("[Muren]")) {
        element = "wall";
      }
      if (parentElement.includes("[Daken]")) {
        element = "roof";
      }
      if (parentElement.includes("[Dakkapellen]")) {
        element = "dormer";
      }

      switch (element) {
        case "wall":
          parsedWallId = parentElement.replace("[Muren]", "").trim();
          break;
        case "roof":
          parsedRoofId = parentElement.replace("[Daken]", "").trim();
          break;
        case "dormer":
          parsedDormerId = parentElement.replace("[Dakkapellen]", "").trim();
          break;

        default:
          break;
      }

      if (action.payload?.windows) {
        action.payload.windows = action.payload?.windows?.map((window: IWindow) => {
          if (window.measure?.glassProduct?.name === "geen") window.measure.glassProduct = null;
        });
      }

      if (parsedWallId) {
        state.data.walls
          .find((wall) => wall.name === parsedWallId)
          ?.windowGroups.push(newWindowGroup);
      }
      if (parsedRoofId) {
        state.data.roofs
          .find((roof) => roof.name === parsedRoofId)
          ?.windowGroups.push(newWindowGroup);
      }
      if (parsedDormerId) {
        state.data.roofs?.forEach((roof) =>
          roof.dormers.forEach((dormer) => {
            if (dormer.name === parsedDormerId) {
              dormer.windowGroups.push(newWindowGroup);
              return;
            }
          }),
        );
      }
      updateIndexedDB(current(state.data));
    },
    editWindowGroup: (state, action) => {
      const { wallId, roofId, dormerId, flatId, parentElement, ...newWindowGroup } = action.payload;
      let parsedWallId: string | undefined = undefined;
      let parsedRoofId: string | undefined = undefined;
      let parsedDormerId: string | undefined = undefined;
      let element = "";
      if (parentElement.includes("[Muren]")) {
        element = "wall";
      }
      if (parentElement.includes("[Daken]")) {
        element = "roof";
      }
      if (parentElement.includes("[Dakkapellen]")) {
        element = "dormer";
      }

      switch (element) {
        case "wall":
          parsedWallId = parentElement.replace("[Muren]", "").trim();
          break;
        case "roof":
          parsedRoofId = parentElement.replace("[Daken]", "").trim();
          break;
        case "dormer":
          parsedDormerId = parentElement.replace("[Dakkapellen]", "").trim();
          break;

        default:
          break;
      }
      if (action.payload?.windows) {
        action.payload.windows = action.payload?.windows?.map((window: IWindow) => {
          if (window.measure?.glassProduct?.name === "geen") window.measure.glassProduct = null;
        });
      }

      if (parsedWallId) {
        state.data.walls?.forEach((wall) => {
          wall.windowGroups = wall.windowGroups.filter((frame) => frame.name !== flatId);
        });
        state.data.walls
          .find((wall) => wall.name === parsedWallId)
          ?.windowGroups.push(newWindowGroup);
      }
      if (parsedRoofId) {
        state.data.roofs?.forEach((roof) => {
          roof.windowGroups = roof.windowGroups.filter((frame) => frame.name !== flatId);
        });
        state.data.roofs
          .find((roof) => roof.name === parsedRoofId)
          ?.windowGroups.push(newWindowGroup);
      }
      if (parsedDormerId) {
        state.data.roofs?.forEach((roof) =>
          roof.dormers.forEach((dormer) => {
            if (dormer.name === parsedDormerId) {
              dormer.windowGroups = dormer.windowGroups.filter((frame) => frame.name !== flatId);
            }
          }),
        );

        state.data.roofs?.forEach((roof) =>
          roof.dormers.forEach((dormer) => {
            if (dormer.name === parsedDormerId) {
              dormer.windowGroups.push(newWindowGroup);
              return;
            }
          }),
        );
      }
      updateIndexedDB(current(state.data));
    },
    deleteWindowGroup: (state, action) => {
      const { wallId, roofId, dormerId, flatId, ...newWindowGroup } = action.payload;
      if (wallId) {
        state.data.walls?.forEach((wall) => {
          wall.windowGroups = wall.windowGroups.filter((frame) => frame.name !== flatId);
        });
      }
      if (roofId) {
        state.data.roofs?.forEach((roof) => {
          roof.windowGroups = roof.windowGroups.filter((frame) => frame.name !== flatId);
        });
      }
      if (dormerId) {
        state.data.roofs?.forEach((roof) =>
          roof.dormers.forEach((dormer) => {
            if (dormer.name === dormerId) {
              dormer.windowGroups = dormer.windowGroups.filter((frame) => frame.name !== flatId);
            }
          }),
        );
      }
      updateIndexedDB(current(state.data));
    },
    initEmptyInspection: (state, action) => {
      const sphId = Number(action.payload);
      const data = {
        sphId,
        creationTime: new Date().toISOString(),
        updateTime: new Date().toISOString(),
        address: "",
        accessibility: "",
        isScaffoldingRequired: false,
        hoseDistance: undefined,
        wallDrillDiameter: "",
        wallCavityBrushCount: 0,

        building: {
          constructionYear: 0,
          buildingType: undefined,
          ridgeHeight: undefined,
          ventilationType: undefined,
          storeyCount: 1,
          storeyAreas: [NaN],
          heatRecoveryInstallationType: undefined,
          heatingTemperatureClass: undefined,
          boilerType: undefined,
          errors: [],
        },
        floors: [] as IFloor[],
        walls: [] as IWall[],
        roofs: [] as IRoof[],
        atticFloor: null,
        solar: {
          images: [],
          currentPhaseConnection: undefined,
          requiredPhaseConnection: undefined,
          measures: [],
        },
        images: [],
        notes: {
          internal: "",
          ownerFloor: "",
          ownerWall: "",
          ownerRoof: "",
          ownerSolar: "",
          ownerWindows: [""],
          supplierFloor: "",
          supplierWall: "",
          supplierRoof: "",
          supplierSolar: "",
          supplierWindows: [""],
        },
        ownerPreferences: {
          floor: { preference: "", reason: "" },
          wall: { preference: "", reason: "" },
          roof: { preference: "", reason: "" },
          solar: { preference: "", reason: "" },
          windows: [{ preference: "", reason: "" }],
        },
      };

      state.data = data;
      updateIndexedDB(data);
    },
    syncStateWithIndexDB: (state, action) => {
      state.data = action.payload;
    },
    setStoreyCount: (state, action) => {
      state.data.building.storeyCount = action.payload;

      const ownerWindowNotesCount = state.data.notes.ownerWindows.length;
      if (ownerWindowNotesCount === 1 && state.data.notes.ownerWindows[0].length === 0) {
        state.data.notes.ownerWindows = new Array(action.payload).fill("");
      } else if (ownerWindowNotesCount > action.payload) {
        state.data.notes.ownerWindows.splice(
          action.payload,
          ownerWindowNotesCount - action.payload,
        );
      } else if (ownerWindowNotesCount < action.payload) {
        const emptyArr = new Array(action.payload - ownerWindowNotesCount).fill("") as string[];
        state.data.notes.ownerWindows = [...state.data.notes.ownerWindows, ...emptyArr];
      }

      const supplierWindowNotesCount = state.data.notes.supplierWindows.length;
      if (supplierWindowNotesCount === 1 && state.data.notes.supplierWindows[0].length === 0) {
        state.data.notes.supplierWindows = new Array(action.payload).fill("");
      } else if (supplierWindowNotesCount > action.payload) {
        state.data.notes.supplierWindows.splice(
          action.payload,
          supplierWindowNotesCount - action.payload,
        );
      } else if (supplierWindowNotesCount < action.payload) {
        const emptyArr = new Array(action.payload - supplierWindowNotesCount).fill("") as string[];
        state.data.notes.supplierWindows = [...state.data.notes.supplierWindows, ...emptyArr];
      }
      updateIndexedDB(current(state.data));
    },
    setStoreyAreas: (state, action) => {
      state.data.building.storeyAreas = action.payload.storeyAreas;
      updateIndexedDB(current(state.data));
    },
    updateStoreyArea: (state, action) => {
      const { area, index }: { area: number; index: number } = action.payload;
      state.data.building.storeyAreas.splice(index, 1, area);
      updateIndexedDB(current(state.data));
    },
    /* 
    1. passing name and house part as props (parentElement optional)
    2. switch - case by house part
    3. find element and it's index
    4. copy the element, modify name (add duplicate-${index})
    5. save the element to store and update IndexedDB
    */
    duplicateElement: (state, action) => {
      const {
        name,
        housePart,
        parentElement,
      }: { name: string; housePart: string; parentElement?: string } = action.payload;
      let elementToDuplicate, arrLength: undefined | number;
      switch (housePart) {
        case "wall":
          elementToDuplicate = current(state.data.walls.find((el) => el.name === name));
          arrLength = current(state.data.walls).length;
          if (elementToDuplicate) {
            const duplicateElement = { ...elementToDuplicate };
            duplicateElement.name = `${name} (kopie${arrLength})`;
            duplicateElement.images = [];
            duplicateElement.notes = "";
            state.data.walls.push(duplicateElement);
            updateIndexedDB(current(state.data));
          }
          break;
        case "wallWindowFrame":
          elementToDuplicate = current(
            state.data.walls.find((wall) =>
              wall.windowGroups.find((windowFrame) => windowFrame.name === name),
            ),
          );
          arrLength = elementToDuplicate?.windowGroups.length;
          if (elementToDuplicate && arrLength) {
            const wallName = elementToDuplicate.name;
            const windowFrame = elementToDuplicate.windowGroups.find(
              (frame) => frame.name === name,
            );
            if (windowFrame) {
              const windowFrameToDuplicate = { ...windowFrame };
              windowFrameToDuplicate.name = `${name} (kopie${arrLength})`;
              windowFrameToDuplicate.notes = "";
              windowFrameToDuplicate.images = [];
              state.data.walls
                .find((wall) => wall.name === wallName)
                ?.windowGroups.push(windowFrameToDuplicate);
              updateIndexedDB(current(state.data));
            }
          }
          break;
        case "roofWindowFrame":
          elementToDuplicate = current(
            state.data.roofs.find((roof) =>
              roof.windowGroups.find((windowFrame) => windowFrame.name === name),
            ),
          );
          arrLength = elementToDuplicate?.windowGroups.length;
          if (elementToDuplicate && arrLength) {
            const roofName = elementToDuplicate.name;
            const windowFrame = elementToDuplicate.windowGroups.find(
              (frame) => frame.name === name,
            );
            if (windowFrame) {
              const windowFrameCopy = { ...windowFrame };
              windowFrameCopy.name = `${name} (kopie${arrLength})`;
              windowFrameCopy.notes = "";
              windowFrameCopy.images = [];
              state.data.roofs
                .find((roof) => roof.name === roofName)
                ?.windowGroups.push(windowFrameCopy);
              updateIndexedDB(current(state.data));
            }
          }
          break;
        case "dormerWindowFrame":
          elementToDuplicate = current(
            state.data.roofs.find((roof) =>
              roof.dormers.find((dormer) => dormer.name === parentElement),
            ),
          );
          arrLength = elementToDuplicate?.dormers.find((dormer) => dormer.name === parentElement)
            ?.windowGroups.length;
          if (elementToDuplicate && arrLength && parentElement) {
            const windowFrame = elementToDuplicate.dormers
              .find((dormer) => dormer.name === parentElement)
              ?.windowGroups.find((frame) => frame.name === name);
            if (windowFrame) {
              const windowFrameCopy = { ...windowFrame };
              windowFrameCopy.name = `${name} (kopie${arrLength})`;
              windowFrameCopy.notes = "";
              windowFrameCopy.images = [];
              state.data.roofs.find((roof) =>
                roof.dormers
                  .find((dormer) => dormer.name === parentElement)
                  ?.windowGroups.push(windowFrameCopy),
              );
              updateIndexedDB(current(state.data));
            }
          }
          break;
        case "floor":
          elementToDuplicate = current(state.data.floors.find((el) => el.name === name));
          arrLength = current(state.data.floors).length;
          if (elementToDuplicate) {
            const duplicateElement = { ...elementToDuplicate };
            duplicateElement.name = `${name} (kopie${arrLength})`;
            duplicateElement.notes = "";
            duplicateElement.images = [];
            state.data.floors.push(duplicateElement);
            updateIndexedDB(current(state.data));
          }
          break;
        case "solarArray":
          elementToDuplicate = current(
            state.data.solar?.measures.find((solar) => solar.name === name),
          );
          arrLength = current(state.data.solar?.measures).length;
          if (elementToDuplicate) {
            const duplicateElement = { ...elementToDuplicate };
            duplicateElement.name = `${name} (kopie${arrLength})`;
            duplicateElement.images = [];
            state.data.solar.measures.push(duplicateElement);
            updateIndexedDB(current(state.data));
          }
          break;
        case "roof":
          elementToDuplicate = current(state.data.roofs.find((el) => el.name === name));
          arrLength = current(state.data.roofs).length;
          if (elementToDuplicate) {
            const duplicateElement = { ...elementToDuplicate };
            duplicateElement.name = `${name} (kopie${arrLength})`;
            duplicateElement.notes = "";
            duplicateElement.images = [];
            state.data.roofs.push(duplicateElement);
            updateIndexedDB(current(state.data));
          }
          break;
        case "dormer":
          elementToDuplicate = current(
            state.data.roofs.find((roof) => roof.dormers.find((dormer) => dormer.name === name)),
          );
          arrLength = elementToDuplicate?.dormers.length;
          if (elementToDuplicate && arrLength) {
            const dormerElement = elementToDuplicate.dormers.find((dormer) => dormer.name === name);
            if (dormerElement) {
              const dormerCopy = { ...dormerElement };
              dormerCopy.name = `${name}_[${elementToDuplicate.name}] (kopie${arrLength})`;
              dormerCopy.images = [];
              state.data.roofs
                .find((roof) => roof.name === dormerCopy.roofName)
                ?.dormers.push(dormerCopy);
              updateIndexedDB(current(state.data));
            }
          }
          break;
        default:
          break;
      }
    },
    setInitialWindowsPreferences: (state, action) => {
      const { storeyCount }: { storeyCount: number } = action.payload;
      if (storeyCount > 1) {
        state.data.ownerPreferences.windows = Array(storeyCount).fill(
          { preference: "", reason: "" },
          0,
          storeyCount,
        );
        updateIndexedDB(current(state.data));
      }
    },
    saveOwnerPreferences: (state, action: PayloadAction<SummarySchemaType>) => {
      // typedEntries wraps Object.entries to return key types
      for (const [property, val] of typedEntries(action.payload)) {
        if (Array.isArray(val) && property === "windows") {
          for (const [_winKey, winVal] of Object.entries(val)) {
            if (winVal.preference === "accept") {
              winVal.reason = "";
            }
          }
        } else if (!Array.isArray(val)) {
          if (val.preference === "accept") {
            val.reason = "";
          }
        }
      }
      state.data.ownerPreferences = action.payload;
      updateIndexedDB(current(state.data));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInspection.pending, (state) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(fetchInspection.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = Loading.FULLFILLED;
      state.data = action.payload;
      updateIndexedDB(action.payload);
    });
    builder.addCase(fetchInspection.rejected, (state, action: PayloadAction<any>) => {
      state.loading = Loading.REJECTED;
      state.error = action.payload;
    });
  },
});

export const {
  saveRoof,
  deleteRoof,
  saveSolarPanel,
  saveSolarPage,
  deleteSolar,
  saveDormer,
  deleteDormer,
  saveAdditionalInspectionData,
  saveAtticFloor,
  saveNotes,
  saveWindowNotes,
  saveHouseInfo,
  updateFloorArea,
  deleteFloorArea,
  saveWall,
  deleteWall,
  saveFloorData,
  saveFloorElement,
  editFloorElement,
  deleteFloorElement,
  editRoofElement,
  editDormer,
  addWindowGroup,
  deleteWindowGroup,
  editWindowGroup,
  initEmptyInspection,
  syncStateWithIndexDB,
  deleteAtticFloor,
  setStoreyCount,
  setStoreyAreas,
  updateStoreyArea,
  duplicateElement,
  setInitialWindowsPreferences,
  saveOwnerPreferences,
} = inspectionSlice.actions;

export const getRoofsWallsDormers = (state: RootState) => {
  const elementNames: string[] = [];
  state.inspection.data.walls?.forEach((wall) => elementNames.push("[Muren] " + wall.name));
  state.inspection.data.roofs?.forEach((roof) => elementNames.push("[Daken] " + roof.name));
  state.inspection.data.roofs?.forEach((roof) => {
    roof.dormers?.forEach((dormer) => elementNames.push("[Dakkapellen] " + dormer.name));
  });
  return elementNames;
};
export const getInspectionData = (state: RootState) => {
  return {
    sphId: state.inspection.data.sphId,
    address: state.inspection.data.address,
    accessibility: state.inspection.data.accessibility,
    isScaffoldingRequired: state.inspection.data.isScaffoldingRequired,
    hoseDistance: state.inspection.data.hoseDistance,
    wallDrillDiameter: state.inspection.data.wallDrillDiameter,
    wallCavityBrushCount: state.inspection.data.wallCavityBrushCount,
  };
};
export const getWallGeneralInfo = (state: RootState) => {
  return {
    isScaffoldingRequired: state.inspection.data?.isScaffoldingRequired,
    wallDrillDiameter: state.inspection.data?.wallDrillDiameter,
    wallCavityBrushCount: state.inspection.data?.wallCavityBrushCount,
    ownerWall: state.inspection.data?.notes?.ownerWall,
    supplierWall: state.inspection.data?.notes?.supplierWall,
  };
};
const getStoreys = (state: RootState) =>
  state.inspection.data?.building?.storeyAreas?.length
    ? state.inspection.data.building.storeyAreas
    : [];
export const getStoreyAreas = createSelector([getStoreys], (storeys) =>
  storeys?.map((el, index) => index.toString()),
);
export const getHouseInfoData = (state: RootState) => {
  return {
    images: state.inspection.data?.images,
    building: state.inspection.data?.building,
    accessibility: state.inspection.data?.notes?.internal,
    floorAreas: state.inspection.data?.building?.storeyAreas?.map((area, index) => {
      return {
        storey: index,
        storeyName: index === 0 ? "Began woonlaag" : `${index}e woonlaag`,
        area: area,
        width: 0,
        height: 0,
      };
    }),
  };
};
export const getInspectionDataLoadingStatus = (state: RootState) => state.inspection.loading;
export const getFloorsData = (state: RootState) => state.inspection.data?.floors;
export const getWallsData = (state: RootState) => state.inspection.data.walls;
export const getRoofsData = (state: RootState) => state.inspection.data.roofs;
export const getNotesData = (state: RootState) => state.inspection.data.notes;
export const getOwnerPreferencesData = (state: RootState) => state.inspection.data.ownerPreferences;
export const getAtticFloorData = (state: RootState) => state.inspection.data.atticFloor;
export const getSolarData = (state: RootState) => state.inspection.data.solar;
export const getSolarByMeasure = (name: string) => (state: RootState) => {
  return state.inspection.data.solar?.measures.find((solar) => solar.name === name);
};
export const getSolarByImages = (state: any, name: string) => {
  return state.inspection.data.solar?.images.find((solar: any) => solar.name === name);
};
export const getRoofByName = (state: RootState, name: string) => {
  return state.inspection.data.roofs?.find((roof) => roof.name === name);
};
export const getDormers = (state: RootState) => {
  return state.inspection.data.roofs?.reduce((acc: IDormer[], roof: IRoof) => {
    const newDormers = roof.dormers?.map((dormer: IDormer) => {
      return {
        ...dormer,
        roofName: roof.name,
      };
    });

    return acc.concat(newDormers);
  }, []);
};
export const getDormerByName = (state: RootState, name: string) => {
  return getDormers(state)?.find((dormer) => dormer.name === name);
};
export const getFloorByName = (state: RootState, name: string) => {
  return getFloorsData(state)?.find((floor) => floor.name === name);
};
export const getWallByName = (name: string) => (state: RootState) =>
  state.inspection.data.walls?.find((wall) => wall.name === name);

export const getUsableSurfaces = (state: RootState) => {
  const usableSurfaces: { floor: number; area: number }[] = [];
  state.inspection.data.floors.forEach((floor: { area: number }, index: number) => {
    usableSurfaces.push({
      floor: index,
      area: floor.area,
    });
  });

  return usableSurfaces;
};

export const getWindowFrameByName = (
  state: RootState,
  flatId?: string,
  roofId?: string,
  wallId?: string,
  dormerId?: string,
) => {
  if (roofId) {
    const windowFrame = state.inspection.data.roofs
      ?.find((roof) => roof.name === roofId)
      ?.windowGroups?.find((group) => group.name === flatId);
    return windowFrame;
  }
  if (wallId) {
    const windowFrame = state.inspection.data.walls
      ?.find((wall) => wall.name === wallId)
      ?.windowGroups?.find((group) => group.name === flatId);

    return windowFrame;
  }
  if (dormerId) {
    let windowFrame = undefined;
    state.inspection.data.roofs?.forEach((roof) =>
      roof.dormers.forEach((dormer) => {
        if (dormer.name === dormerId) {
          windowFrame = dormer.windowGroups.find((frame) => frame.name === flatId);
        }
      }),
    );

    return windowFrame;
  }
};

const SelectWallElementsWindowGroups = (state: RootState) => {
  const walls: Pick<IWall, "name" | "errors" | "area" | "direction" | "windowGroups">[] = [];
  state.inspection.data.walls?.map(
    (wall: {
      name: string;
      area: number;
      direction: Direction;
      errors?: string[] | [];
      windowGroups: IWindowGroup[];
    }) =>
      walls.push({
        name: wall.name,
        area: wall.area,
        direction: wall.direction,
        errors: wall.errors,
        windowGroups: wall.windowGroups,
      }),
  );
  return walls;
};
const SelectRoofElementsWindowGroups = (state: RootState) => {
  const roofs: Pick<IRoof, "name" | "windowGroups" | "dormers">[] = [];
  state.inspection.data.roofs?.map(
    (roof: { name: string; windowGroups: IWindowGroup[]; dormers: IDormer[] }) =>
      roofs.push({ name: roof.name, windowGroups: roof.windowGroups, dormers: roof.dormers }),
  );
  return roofs;
};

export const getWallsAndRoof = createSelector(
  [SelectWallElementsWindowGroups, SelectRoofElementsWindowGroups],
  (walls, roofs) => {
    return { walls, roofs };
  },
);

const SelectSolarPanel = (state: RootState) => {
  const solarArr: ISolarPanel["measures"] = [];
  state.inspection.data.solar?.measures.map((solar) => solarArr.push(solar));
  return solarArr;
};

export const getWalls = createSelector(SelectWallElementsWindowGroups, (walls) => {
  return walls;
});

export const getSolar = createSelector([SelectSolarPanel], (solarArr) => {
  return { solarArr };
});

export const getSolarCount = createSelector([SelectSolarPanel], (solarArr) => {
  return solarArr.length;
});

export const getSolarObject = (state: RootState) => {
  return state.inspection.data.solar;
};

export const getCompletedInspection = (state: RootState) => {
  return state.inspection.data;
};

const buildingConstructionYear = (state: RootState) =>
  state.inspection.data?.building?.constructionYear;

export const getBuildingConstructionYear = createSelector(
  [buildingConstructionYear],
  (year) => year,
);

const SelectRoofDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data?.roofs?.length) {
      return [
        {
          housePartName: t("roofs"),
          housePartsWithWindowGroup: state.inspection.data.roofs?.map((roof) => {
            return {
              name: roof?.name,
              path: `/houses/${state.inspection.data.sphId}/roof/add-roof-element/${encodeURI(
                roof.name,
              )}`,
              errors: roof?.errors,
              windowFrames: roof?.windowGroups?.map((windowFrame) => {
                return {
                  name: windowFrame?.name,
                  path: `/houses/${state.inspection.data.sphId}/flat/${encodeURI(
                    windowFrame.name,
                  )}/add-window/wall/${encodeURI(roof.name)}`,
                  errors: windowFrame?.errors,
                };
              }),
            };
          }),
        },
      ];
    }
    return null;
  };

export const getRoofDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectRoofDashboard(t)], (roof) => roof);

const SelectWallsDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data?.walls?.length) {
      return [
        {
          housePartName: t("walls"),
          housePartsWithWindowGroup: state.inspection.data.walls?.map((wall) => {
            return {
              name: wall?.name,
              path: `/houses/${state.inspection.data.sphId}/wall/add-wall-element/${encodeURI(
                wall.name,
              )}`,
              errors: wall?.errors,
              windowFrames: wall?.windowGroups?.map((windowFrame) => {
                return {
                  name: windowFrame?.name,
                  path: `/houses/${state.inspection.data.sphId}/flat/${encodeURI(
                    windowFrame.name,
                  )}/add-window/wall/${encodeURI(wall.name)}`,
                  errors: windowFrame?.errors,
                };
              }),
            };
          }),
        },
      ];
    }
    return null;
  };

export const getWallsDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectWallsDashboard(t)], (wall) => wall);

const SelectFloorDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data?.floors?.length) {
      return [
        {
          housePartName: t("floor"),
          housePartsWithWindowGroup: state.inspection.data.floors?.map((floor) => {
            return {
              name: floor?.name,
              path: `/houses/${state.inspection.data.sphId}/floor/add-floor-element/${encodeURI(
                floor.name,
              )}`,
              errors: floor?.errors,
              windowFrames: [],
            };
          }),
        },
      ];
    }
    return null;
  };

export const getFloorsDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectFloorDashboard(t)], (floor) => floor);

const SelectSolarPanelsDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data?.solar?.measures?.length) {
      return [
        {
          housePartName: t("solarPanels"),
          housePartsWithWindowGroup: state.inspection.data.solar?.measures.map((measure) => {
            return {
              name: measure.name,
              path: `/houses/${state.inspection.data.sphId}/solar/add-solar-element/${encodeURI(
                measure.name,
              )}`,
              errors: measure.errors,
              windowFrames: [],
            };
          }),
        },
      ];
    }
    return null;
  };

export const getSolarDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectSolarPanelsDashboard(t)], (solar) => solar);

const SelectAtticDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data.atticFloor) {
      return [
        {
          housePartName: t("atticFloor"),
          housePartsWithWindowGroup: [
            {
              name: t("atticFloor"),
              path: `/houses/${state.inspection.data.sphId}/roof/add-attic-floor`,
              errors: state.inspection.data.atticFloor?.errors,
              windowFrames: [],
            },
          ],
        },
      ];
    }
    return null;
  };

export const getAtticDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectAtticDashboard(t)], (attic) => attic);

const SelectGeneralDashboard =
  (t: TFunction<"translation", undefined, "translation">) => (state: RootState) => {
    if (state.inspection.data?.building) {
      return [
        {
          housePartName: t("general"),
          housePartsWithWindowGroup: [
            {
              name: t("general"),
              path: `/houses/${state.inspection.data.sphId}/details`,
              errors: state.inspection.data?.building.errors,
              windowFrames: [],
            },
          ],
        },
      ];
    }
    return null;
  };

export const getGeneralDashboard = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectGeneralDashboard(t)], (building) => building);

const selectSummaryData = (state: RootState) => {
  const summaryData = new Map<keyof typeof SummaryElements, SummaryData>();
  if (state.inspection.data?.floors?.length) {
    const floors = state.inspection.data.floors.map((floor) => {
      return {
        name: floor.name,
        insulation: floor.measure.floorInsulationProduct,
        soilInsulation: floor.measure.soilInsulationProduct,
      };
    });
    const ownerAgreements = state.inspection?.data?.notes?.ownerFloor;
    const floorsWithOwnerAgreement: SummaryData = { elements: floors, ownerAgreements };
    floors.length && summaryData.set("Floor", floorsWithOwnerAgreement);
  }
  if (state.inspection.data?.walls?.length) {
    const walls = state.inspection.data.walls.map((wall) => {
      return { name: wall.name, insulation: wall.measure.insulationProduct };
    });
    const ownerAgreements = state.inspection?.data?.notes?.ownerWall;
    const wallsWithOwnerAgreements = { elements: walls, ownerAgreements };
    if (walls.length) summaryData.set("Walls", wallsWithOwnerAgreements);
  }
  if (state.inspection.data?.roofs?.length) {
    const roofs = state.inspection.data?.roofs?.map((roof) => {
      return { name: roof.name, insulation: roof.measure.insulationProduct };
    });
    const ownerAgreements = state.inspection?.data?.notes?.ownerRoof;
    const atticFloor = !!state.inspection.data.atticFloor;
    const roofsWithOwnerAgreements = { elements: roofs, ownerAgreements, atticFloor };
    if (roofs.length) summaryData.set("Roof", roofsWithOwnerAgreements);
  }
  if (state.inspection.data?.solar?.measures.length) {
    const solar = state.inspection?.data?.solar?.measures?.map((solar) => {
      return { name: solar.name, panelCount: solar.panelCount };
    });
    const ownerAgreements = state.inspection?.data?.notes?.ownerSolar;
    const solarWithOwnerAgreements = { elements: solar, ownerAgreements };
    if (solar.length) summaryData.set("Solar", solarWithOwnerAgreements);
  }
  return summaryData;
};
export const getSummaryData = () =>
  createSelector([selectSummaryData], (summaryData) => summaryData);

const selectWindowsSummary = (state: RootState) => {
  const storeyCount = state.inspection?.data?.building?.storeyCount;

  const windowGroupsPerStorey = Array.from(
    { length: storeyCount },
    () =>
      <
        {
          windowGroups: { name: string; windowsCount: number; insulation: boolean }[];
          ownerAgreements: string;
          title: string;
        }
      >{
        ownerAgreements: "",
        windowGroups: [],
        title: "",
      },
  );

  state.inspection?.data?.walls?.forEach((wall) =>
    wall.windowGroups.forEach((windowFrame) => {
      windowGroupsPerStorey[windowFrame.storey].windowGroups?.push({
        name: windowFrame.name,
        windowsCount: windowFrame.windows.length,
        insulation: windowFrame.windows.every(
          (window) =>
            window.measure.glassProduct !== null && window.measure?.glassProduct.name.length > 0,
        ),
      });
    }),
  );
  state.inspection?.data?.roofs?.forEach((roof) => {
    roof.windowGroups.forEach((windowFrame) => {
      windowGroupsPerStorey[windowFrame.storey].windowGroups?.push({
        name: windowFrame.name,
        windowsCount: windowFrame.windows.length,
        insulation: windowFrame.windows.every(
          (window) =>
            window.measure.glassProduct !== null && window.measure?.glassProduct.name.length > 0,
        ),
      });
    });
    roof.dormers.forEach((dormer) =>
      dormer.windowGroups.forEach((windowFrame) => {
        windowGroupsPerStorey[windowFrame.storey].windowGroups?.push({
          name: windowFrame.name,
          windowsCount: windowFrame.windows.length,
          insulation: windowFrame.windows.every(
            (window) =>
              window.measure.glassProduct !== null && window.measure?.glassProduct.name.length > 0,
          ),
        });
      }),
    );
  });

  state.inspection?.data?.notes?.ownerWindows.forEach((ownerAgreement, index) => {
    windowGroupsPerStorey[index].ownerAgreements = ownerAgreement;
    windowGroupsPerStorey[index].title =
      index === 0 ? "Ruiten began woonlaag" : `Ruiten ${index}e woonlaag`;
  });

  return windowGroupsPerStorey;
};

export const getWindowsSummary = () =>
  createSelector([selectWindowsSummary], (windowsSummary) => windowsSummary);

const SelectErrors = (state: RootState) => {
  const errors: any = [];
  const findError = (inspectionData: any) => {
    for (const field in inspectionData) {
      if (typeof inspectionData[field] === "object" && inspectionData[field] !== null) {
        findError(inspectionData[field]);
        if (field === "errors") {
          if (inspectionData[field].length !== 0) {
            errors.push(inspectionData[field]);
          }
        }
      }
    }
  };

  findError(state.inspection.data);

  return errors;
};

export const getErrors = (t: TFunction<"translation", undefined, "translation">) =>
  createSelector([SelectErrors], (data) => data);

const hasElements = (state: RootState) => {
  return state.inspection.data?.walls?.length ||
    state.inspection.data?.floors?.length ||
    state.inspection.data?.solar?.measures?.length ||
    state.inspection.data?.roofs?.length ||
    state.inspection.data?.building?.constructionYear >= 1700 ||
    (state.inspection.data?.atticFloor && state.inspection.data?.atticFloor?.area > 0)
    ? true
    : false;
};

export const getDashboardHasElements = createSelector([hasElements], (hasElements) => hasElements);
