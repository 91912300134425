import { Box, styled } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { MainButton } from "../../components/common/Button/MainButton";
import { Card } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import {
  DashboardElement,
  DashboardItemContainer,
} from "../../components/common/Dashboard/Dashboard";
import {
  EmptyContainer,
  SectionTitle,
} from "../../components/common/StepComponents/StepContainers";
import NetworkStatus from "../../components/NetworkStatus/NetworkStatus";
import { Path } from "../../routes";
import { useAppSelector } from "../../store/hooks";
import {
  getAtticDashboard,
  getDashboardHasElements,
  getFloorsDashboard,
  getRoofDashboard,
  getSolarDashboard,
  getWallsDashboard,
  getErrors,
} from "../../store/slices";
import {
  FloorIcon,
  GeneralInfo,
  HouseIcon,
  RoofIcon,
  SolarIcon,
} from "../../components/common/Icons";

import { getGeneralDashboard } from "../../store/slices";

// Needs to be here to avoid rendering issues
const DashboardCard = styled(Card)(() => ({
  padding: 0,
}));

const StyledDashboardItemContainer = styled(DashboardItemContainer)(({ theme }) => ({
  padding: theme.spacing(3),
  borderBottom: `2px solid ${theme.palette.border.main}`,
  ":last-of-type": {
    border: "none",
  },
}));

export const Dashboard: FC = () => {
  const [enableSubmit, setEnableSubmit] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hasElements = useAppSelector(getDashboardHasElements);
  const dashboardRoof = useAppSelector(getRoofDashboard(t));
  const dashboardAttic = useAppSelector(getAtticDashboard(t));
  const dashboardWalls = useAppSelector(getWallsDashboard(t));
  const dashboardFloors = useAppSelector(getFloorsDashboard(t));
  const dashboardSolar = useAppSelector(getSolarDashboard(t));
  const dashboardGeneral = useAppSelector(getGeneralDashboard(t));
  const dashboardErrors = useAppSelector(getErrors(t));
  const { id } = useParams();
  useEffect(() => {
    const activateSubmit = () => {
      if (
        dashboardRoof !== null &&
        dashboardWalls !== null &&
        dashboardSolar !== null &&
        dashboardFloors !== null &&
        dashboardGeneral !== null
      ) {
        if (dashboardErrors.length === 0) {
          setEnableSubmit(true);
        } else {
          setEnableSubmit(false);
        }
      } else {
        setEnableSubmit(false);
      }
    };
    activateSubmit();
  }, [enableSubmit]);
  return (
    <MainPageWrapper>
      <Box px={7} mt={3} ml={"auto"}>
        <NetworkStatus />
      </Box>
      <PageWrapper>
        <SectionTitle title={t("houseStructure")} sx={{ mt: 5 }} />
        {!hasElements ? (
          <EmptyContainer title={t("noElementsAdded")} />
        ) : (
          <>
            <DashboardCard>
              {dashboardGeneral &&
                dashboardGeneral.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={GeneralInfo}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
              {dashboardRoof &&
                dashboardRoof.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={RoofIcon}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
              {dashboardAttic &&
                dashboardAttic.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={FloorIcon}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
              {dashboardWalls &&
                dashboardWalls.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={HouseIcon}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
              {dashboardFloors &&
                dashboardFloors.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={FloorIcon}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
              {dashboardSolar &&
                dashboardSolar.map((houseEl) => (
                  <StyledDashboardItemContainer
                    key={houseEl.housePartName}
                    Icon={SolarIcon}
                    text={houseEl.housePartName}
                    alt={houseEl.housePartName}
                  >
                    {houseEl.housePartsWithWindowGroup &&
                      houseEl.housePartsWithWindowGroup?.map((part) => (
                        <DashboardElement
                          key={part.name}
                          title={part.name}
                          element={part.windowFrames}
                          parentErrorMessage={part.errors}
                          handleHousePartEdit={() => navigate(`${part.path}`)}
                        />
                      ))}
                  </StyledDashboardItemContainer>
                ))}
            </DashboardCard>
            <MainButton
              text={t("toSummary")}
              variant={enableSubmit ? "primary" : "disabled"}
              sx={{ my: 3, width: "100%" }}
              handleClick={() => navigate(Path.SignAndSubmit, { state: { id } })}
            />
          </>
        )}
      </PageWrapper>
    </MainPageWrapper>
  );
};
