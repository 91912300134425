import { Box, TextField, ToggleButton, styled } from "@mui/material";

export const StyledContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: theme.spacing(3),
  borderStyle: "solid",
  borderWidth: "3px",
}));

export const StyledToggleButton = styled(ToggleButton, {
  shouldForwardProp: (prop) => prop !== "hasErr",
})<{ hasErr: boolean }>(({ theme, hasErr }) => ({
  "&.MuiToggleButtonGroup-grouped": {
    borderRadius: "50% !important",
    border: hasErr
      ? "3px solid red !important"
      : `1px solid ${theme.palette.border.dark} !important`,
  },
  "&.Mui-selected.accept": {
    backgroundColor: `${theme.palette.primary[100]} !important`,
    "& .MuiSvgIcon-root": { fill: "#FFF" },
  },
  "&.Mui-selected.doubt": {
    backgroundColor: `${theme.palette.primary[700]} !important`,
    "& .MuiSvgIcon-root": { fill: theme.palette.primary[800] },
  },
  "&.Mui-selected.decline": {
    backgroundColor: `${theme.palette.primary[800]} !important`,
    "& .MuiSvgIcon-root": { fill: "#FFF" },
  },
}));

export const Textarea = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "hasErr",
})<{
  hasErr?: boolean;
}>(({ theme, hasErr }) => ({
  border: hasErr ? "3px solid red !important" : `2px solid ${theme.palette.primary[800]}`,
  borderRadius: "10px",
  lineHeight: "1.5",
  fontSize: 16,
  fontFamily: "Roboto Flex",
  fontWeight: "normal",
}));

export const SummaryHeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
// width={"100%"}
//       p={2}
//       px={4}
//       display={"flex"}
//       justifyContent={"space-between"}
//       alignItems={"center"}
