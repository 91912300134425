import { Box, InputAdornment, MenuItem, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Card } from "../../components/common/Container/Containers";
import { TwoColumnsContainer } from "../../components/common/Container/TwoColumnsContainer";
import { SectionTitle } from "../../components/common/StepComponents/StepContainers";
import { FormStack } from "../../components/common/Form/FormStack";
import { FormInput } from "../../components/common/Form/FormInput";
import EditIcon from "@mui/icons-material/Edit";
import { SurfaceArea } from "../../components/common/SurfaceArea";
import { MainButton } from "../../components/common/Button/MainButton";
import { FormSelect } from "../../components/common/Form/FormSelect";
import { Category, DefaultValues, GlassType, IWindow, WindowType } from "../../types";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import { StyledCheckbox } from "../../components/common/StepComponents/Checkbox.styled";
import { useAppSelector } from "../../store/hooks";
import { getCategorySpecificProducts } from "../../store/slices";
import { yupResolver } from "@hookform/resolvers/yup";
import { getWindowSchema } from "../../types/schema";
import { useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";
import isNameUnique from "../../utils/helpers/nameChecker";
import stringToNumber from "../../utils/helpers/strToNum";

type SituationProps = {
  form: any;
};

type newSituationProps = {
  insulationMaterials: { id: string; name: string }[];
} & SituationProps;

const CurrentSituation: FC<SituationProps> = ({ form }) => {
  const [showSurface, setShowSurface] = useState(false);
  const {
    register,
    formState: { errors },
    watch,
  } = form;
  const windowType = watch("windowType");
  const glassType = watch("glassType");
  const { t } = useTranslation();

  return (
    <Card sx={{ backgroundColor: "#F8F8F8", height: "100%" }}>
      <SectionTitle title={t("currentSituation")} sx={{ fontWeight: 500 }} />
      <FormStack pt={3}>
        <FormInput {...register("name")} label={t("name")} error={errors.name} />
        <Box>
          <FormInput
            {...register("area", { setValueAs: (v: any) => stringToNumber(v) })}
            label={t("surfaceArea")}
            sx={{ ".MuiInputLabel-shrink": { left: "0" } }}
            error={errors.area}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MainButton
                    variant="white"
                    text={t("edit")}
                    icon={<EditIcon fontSize="small" sx={{ display: "flex" }} />}
                    onClick={() => setShowSurface(!showSurface)}
                  />
                </InputAdornment>
              ),
            }}
          />
          {showSurface && <SurfaceArea form={form} />}
        </Box>
        <FormSelect
          name="glassType"
          form={form}
          label={t("glassType")}
          options={Object.values(GlassType)}
          error={errors.glassType}
        />
        <FormSelect
          name="windowType"
          form={form}
          label={t("windowType")}
          options={Object.values(WindowType)}
          error={errors.windowType}
        />
        <FormInput
          {...register("headThickness", { setValueAs: (v: any) => stringToNumber(v) })}
          label={t("headThickness")}
          error={errors.headThickness}
          disabled={windowType === undefined || glassType !== GlassType.SINGLE}
        />
      </FormStack>
    </Card>
  );
};

const NewSituation: FC<newSituationProps> = ({ form, insulationMaterials }) => {
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = form;
  const { t } = useTranslation();

  const hasVentilation = watch("hasVentilationGrille");

  return (
    <Card sx={{ backgroundColor: "#F8F8F8", px: 2, height: "100%" }}>
      <SectionTitle title={t("newSituation")} sx={{ fontWeight: 500 }} />

      <FormStack pt={3}>
        <FormSelect
          name="measure.glassProduct.name"
          form={form}
          label={t("glazingType")}
          options={insulationMaterials.map((el) => el.name)}
          error={errors.measure?.glassProduct?.name}
        />

        <FormSelect
          name="measure.windowType"
          form={form}
          label={t("windowType")}
          options={Object.values(WindowType)}
          error={errors.measure?.windowType}
        />

        <Controller
          control={control}
          name={"hasVentilationGrille"}
          defaultValue={form.getValues("hasVentilationGrille")}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              label={t("hasVentilationGrille")}
              control={
                <StyledCheckbox
                  disabled={
                    form.getValues("measure.glassProduct.name") === undefined ||
                    form.getValues("measure.glassProduct.name") === "" ||
                    form.getValues("measure.glassProduct.name") === DefaultValues.NONE
                  }
                  checked={value || false}
                  onChange={(val) => {
                    form.trigger();
                    onChange(val);
                    if (hasVentilation === true) {
                      form.setValue("measure.ventilationGrilleWidth", 0);
                    }
                  }}
                />
              }
            />
          )}
        />

        <FormInput
          {...register("measure.ventilationGrilleWidth", {
            setValueAs: (v: any) => stringToNumber(v),
          })}
          label={t("ventilationGrilleWidth")}
          error={errors.measure?.ventilationGrilleWidth}
          disabled={!hasVentilation}
        />
      </FormStack>
    </Card>
  );
};

export const WindowFrame: FC<{
  form: any;
  index: number;
  collapse: (panel: string) => any;
}> = ({ form, index, collapse }) => {
  const { t } = useTranslation();
  const insulationMaterials = useAppSelector(getCategorySpecificProducts(Category.GLASS));
  const window: IWindow = { ...form.getValues("windows")[index] };
  const windows = form.watch("windows");
  const windowForm = useForm<IWindow>({
    resolver: yupResolver(getWindowSchema(t)),
    mode: "all",
    defaultValues: {
      ...window,
      glassType: window?.glassType,
      windowType: window?.windowType,
      area: window?.area,
      hasVentilationGrille: window?.hasVentilationGrille || false,
      headThickness: window?.headThickness,
      measure: {
        ...window?.measure,
        glassProduct: {
          ...window?.measure.glassProduct,
          name:
            window?.measure?.glassProduct === null
              ? "geen"
              : window?.measure?.glassProduct?.name || "",
        },
        windowType: window?.measure.windowType,
      },
    },
  });
  const { trigger, watch } = windowForm;
  const windowName = windowForm.watch("name");
  const glassType = watch("glassType");
  const windowType = watch("windowType");
  const newWindowType = watch("measure.windowType");
  const name = watch("name");
  const glassProduct = watch("measure.glassProduct.name");

  const saveWindow = async () => {
    await windowForm.trigger();
    const selectedInsulationProduct = insulationMaterials.find( (el) => el.name === glassProduct)
    selectedInsulationProduct && windowForm.setValue("measure.glassProduct.id", selectedInsulationProduct.id)

    if (isNameUnique(windows.slice(0, index).concat(windows.slice(index + 1)), windowName)) {
      toast.error(t("uniqueName"));
      return;
    } else {
      windows[index] = {
        ...windowForm.getValues(),
        errors: Object.keys(windowForm.formState.errors),
      };
      form.setValue("windows", windows);
      collapse(`window${index}`);
      toast.success(t("success"));
    }
  };

  const deleteWindow = () => {
    const windows = form.getValues("windows");
    windows.splice(index, 1);
    form.setValue("windows", windows);
    collapse(`window${index}`);
  };

  useEffect(() => {
    trigger();
  }, [trigger, glassType, windowType, newWindowType, name, glassProduct]);

  return (
    <Stack spacing={4}>
      <TwoColumnsContainer
        sx={{ mt: 1, mb: 0 }}
        childrenFirstColumn={<CurrentSituation form={windowForm} />}
        childrenSecondColumn={
          <NewSituation form={windowForm} insulationMaterials={insulationMaterials} />
        }
      />
      <Stack spacing={2} direction="row" display="flex" justifyContent="end">
        <MainButton
          variant="white"
          sx={{ width: "200px" }}
          text={t("delete")}
          onClick={deleteWindow}
        />
        <MainButton
          variant="primary"
          sx={{ width: "200px" }}
          text={t("save")}
          onClick={saveWindow}
        />
      </Stack>
    </Stack>
  );
};
