import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import { Box, Divider, ToggleButtonGroup, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { SummaryAgreement } from "src/types";
import { SummarySchemaType } from "./SummaryCard";
import {
  StyledContainer,
  StyledToggleButton,
  SummaryHeaderContainer,
  Textarea,
} from "./SummaryCard.styled";
import { useTranslation } from "react-i18next";

interface IWindowSummaryCard {
  windowSummary: {
    name: string;
    windowsCount: number;
    insulation: boolean;
  }[];
  title: string;
  storeyOwnerAgreements: string;
  index: number;
}

interface ISummaryHeaderProps {
  index: number;
  title: string;
}

interface ISummaryContentProps {
  index: number;
  selectedOption: string;
  ownerAgreements: string;
  windowSummary: {
    name: string;
    windowsCount: number;
    insulation: boolean;
  }[];
}

const ReasonComponent = ({ index, title }: { index: number; title: string }) => {
  const { t } = useTranslation();
  const { control } = useFormContext<Pick<SummarySchemaType, "windows">>();
  return (
    <Box width={"100%"}>
      <Box display={"flex"} alignItems={"center"} mb={2} mt={4}>
        <FeedOutlinedIcon fontSize="small" sx={{ marginRight: "10px" }} />
        <Typography color={"#003257"} fontSize={"14px"} fontWeight={"600"}>
          {t("reasonFor") + " " + t(title)}
        </Typography>
      </Box>
      <Controller
        name={`windows.${index}.reason`}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <Textarea
              {...field}
              aria-label={`reason for ${title}`}
              placeholder="Type.."
              minRows="10"
              multiline={true}
              sx={{ width: "100%" }}
              hasErr={!!error}
            />
          );
        }}
      />
    </Box>
  );
};

const SummaryHeader = ({ index, title }: ISummaryHeaderProps) => {
  const form = useFormContext<SummarySchemaType>();
  const { control, trigger } = form;
  return (
    <SummaryHeaderContainer>
      <Typography variant="h5" component={"h2"}>
        {title}
      </Typography>
      {/* icons when they are provided */}
      <Controller
        name={`windows.${index}.preference`}
        control={control}
        render={({ field: { onBlur, onChange, ref, value }, fieldState: { error } }) => {
          return (
            <ToggleButtonGroup
              className="parent-windows"
              onBlur={onBlur}
              onChange={(e, option) => {
                onChange(option);
                trigger();
              }}
              ref={ref}
              value={value}
              exclusive
              sx={{ gap: 2 }}
            >
              <StyledToggleButton
                value="accept"
                className="accept"
                selected={value === SummaryAgreement.ACCEPT}
                disabled={value === SummaryAgreement.ACCEPT}
                hasErr={!!error}
              >
                <CheckIcon />
              </StyledToggleButton>
              <StyledToggleButton
                value="doubt"
                className="doubt"
                selected={value === SummaryAgreement.DOUBT}
                disabled={value === SummaryAgreement.DOUBT}
                hasErr={!!error}
              >
                <QuestionMarkIcon />
              </StyledToggleButton>
              <StyledToggleButton
                value="decline"
                className="decline"
                selected={value === SummaryAgreement.DECLINE}
                disabled={value === SummaryAgreement.DECLINE}
                hasErr={!!error}
              >
                <CloseIcon />
              </StyledToggleButton>
            </ToggleButtonGroup>
          );
        }}
      />
    </SummaryHeaderContainer>
  );
};

const SummaryContent = ({
  selectedOption,
  index,
  ownerAgreements,
  windowSummary,
}: ISummaryContentProps) => {
  const { t } = useTranslation();
  return (
    <Box display={"flex"} flexDirection={"column"} p={4} width={"100%"}>
      <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
        {windowSummary.map(
          (windowFrame) =>
            windowFrame.insulation && (
              <Typography key={windowFrame.windowsCount}>
                {`${t("elementName")}: `}
                <Typography component={"span"} variant="caption">
                  {`${windowFrame.name}, `}
                </Typography>
                {`${t("numberOfWindows")}: `}
                <Typography component={"span"} variant="caption">
                  {windowFrame.windowsCount}
                </Typography>
              </Typography>
            ),
        )}
      </Box>
      <Typography variant="body1" fontWeight={"600"}>
        {t("ownerAgreements") + ": "}
      </Typography>
      <Typography>{ownerAgreements}</Typography>
      {selectedOption && selectedOption !== SummaryAgreement.ACCEPT ? (
        <ReasonComponent title={selectedOption} index={index} />
      ) : null}
    </Box>
  );
};

export const WindowSummaryCard = ({
  windowSummary,
  title,
  storeyOwnerAgreements,
  index,
}: IWindowSummaryCard) => {
  const { watch } = useFormContext<Pick<SummarySchemaType, "windows">>();
  const selectedOption = watch(`windows.${index}.preference`);

  return (
    <StyledContainer
      sx={{
        borderColor: selectedOption === "accept" ? "#54C1F0" : "#D6D6D6",
      }}
    >
      <SummaryHeader index={index} title={title} />
      <Divider variant="fullWidth" sx={{ width: "100%" }} />
      <SummaryContent
        windowSummary={windowSummary}
        index={index}
        selectedOption={selectedOption}
        ownerAgreements={storeyOwnerAgreements}
      />
    </StyledContainer>
  );
};
