import { getFloorOverviewSchema } from "../../types/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Stack } from "@mui/material";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form, useParams } from "react-router-dom";
import FloorIcon from "../../assets/icons/floor.svg";
import { MainButton } from "../../components/common/Button/MainButton";
import { Card, CardSection, EmptyContainer } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { Cover } from "../../components/common/Cover/Cover";
import { FormSelect } from "../../components/common/Form/FormSelect";
import { PlusIcon } from "../../components/common/Icons";
import {
  AdditionalData,
  AdditionalDataWrapper,
} from "../../components/common/StepComponents/AdditionalData";
import { SectionTitle } from "../../components/common/StepComponents/StepContainers";
import { DividerStack } from "../../components/common/StepComponents/StepContainers.styled";
import { Path, useNavigation } from "../../routes";
import { useAppSelector } from "../../store/hooks";
import {
  duplicateElement,
  getFilteredSuggestions,
  getFloorsData,
  getInspectionData,
  getNotesData,
  saveFloorData,
} from "../../store/slices";
import { HoseDistance, IFloor, SuggestionCategory } from "../../types";
import { toast } from "react-toastify";
import type { InferType } from "yup";

type FloorsProps = {
  element: IFloor;
  handleClick: () => void;
  handleDuplicate: () => void;
};

const Floors: FC<FloorsProps> = ({ element, handleClick, handleDuplicate }) => {
  return (
    <CardSection
      title={element.name}
      errors={element.errors || []}
      area={element.area}
      icon={FloorIcon}
      path={"/"}
      handleClick={handleClick}
      handleDuplicate={handleDuplicate}
    />
  );
};

export const Flooring: FC = () => {
  const { t } = useTranslation();
  const floorSuggestionsOwner = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.OWNER_FLOOR),
  );
  const floorSuggestionExecutive = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.EXECUTIVE_FLOOR),
  );

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const floors = useAppSelector(getFloorsData);
  const notes = useAppSelector(getNotesData);
  const inspectionData = useAppSelector(getInspectionData);

  const floorSchema = getFloorOverviewSchema(t);
  type FloorType = InferType<typeof floorSchema>;

  const form = useForm<FloorType>({
    mode: "all",
    defaultValues: {
      hoseDistance: inspectionData?.hoseDistance,
      ownerFloor: notes?.ownerFloor,
      supplierFloor: notes?.supplierFloor,
    },
    resolver: yupResolver(floorSchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
  } = form;

  const handleSave = async () => {
    if ("hoseDistance" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("hoseDistance");
      return;
    }

    if ("ownerFloor" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("ownerFloor");
      return;
    }
    dispatch(saveFloorData({ ...form.getValues(), errors: Object.keys(form.formState.errors) }));
    navigation(`/houses/${id}/solar`);
  };

  const handleDuplicate = (floorName: string) =>
    dispatch(duplicateElement({ name: floorName, housePart: "floor" }));

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    reset({
      hoseDistance: inspectionData?.hoseDistance,
      ownerFloor: notes?.ownerFloor,
      supplierFloor: notes?.supplierFloor,
    });
  }, [notes, inspectionData, reset]);

  return (
    <MainPageWrapper>
      <Cover title={t("floor")} />
      <PageWrapper>
        <Stack sx={{ mt: 3 }}>
          <SectionTitle title={t("floors")}>
            <MainButton
              icon={<PlusIcon color="white" width="10px"></PlusIcon>}
              text={t("add")}
              variant="secondary"
              onClick={() => navigation(Path.AddFloorElement)}
            />
          </SectionTitle>
          {floors && floors.length === 0 ? (
            <EmptyContainer title={t("noFloorAdded")} />
          ) : (
            <Card sx={{ px: 0 }}>
              <DividerStack divider={<Divider />} spacing={2}>
                {floors &&
                  floors.length > 0 &&
                  floors?.map((floor) => (
                    <Floors
                      key={floor.name}
                      element={floor}
                      handleClick={() => navigation(Path.AddFloorElement, { floorId: floor?.name })}
                      handleDuplicate={() => handleDuplicate(floor?.name)}
                    />
                  ))}
              </DividerStack>
            </Card>
          )}

          <SectionTitle title={t("additionalInspectionData")} sx={{ mt: 5 }} />
          <AdditionalDataWrapper title={t("general")}>
            <Card width="100%">
              <FormSelect
                name="hoseDistance"
                form={form}
                label={t("hoseDistance")}
                options={Object.values(HoseDistance)}
                error={errors.hoseDistance}
              />
            </Card>
          </AdditionalDataWrapper>

          <Form onSubmit={handleSubmit(handleSave, handleSave)}>
            <AdditionalDataWrapper title={t("ownerAgreements")}>
              <AdditionalData
                name="ownerFloor"
                form={form}
                suggestions={floorSuggestionsOwner}
                error={errors.ownerFloor}
              />
            </AdditionalDataWrapper>
            <AdditionalDataWrapper title={t("contractorAgreements")}>
              <AdditionalData
                name="supplierFloor"
                form={form}
                suggestions={floorSuggestionExecutive}
                error={errors.supplierFloor}
              />
            </AdditionalDataWrapper>

            <MainButton
              text={t("save")}
              variant="primary"
              type="submit"
              sx={{ margin: "30px 0" }}
            />
          </Form>
        </Stack>
      </PageWrapper>
    </MainPageWrapper>
  );
};
