import { Box, BoxProps, SvgIconTypeMap,  Typography } from "@mui/material";
import { FC, FunctionComponent, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { SmallCircleBox } from "../../NetworkStatus/NetworkStatus.styles";
import { MainButton } from "../Button/MainButton";
import { EditIcon, IconBaseProps } from "../Icons";
import { ColumnContainer, DashboardHeader, DashboardHeaderIcon, LShape } from "./Dashboard.styled";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface IDashboardElement {
  title?: string;
  element?: { name: string; path?: string; errors: [] | string[] }[];
  parentErrorMessage?: string[] | [];
  handleHousePartEdit: () => void;
  handleWindowFrameEdit?: () => void;
}

export const DashboardElement = ({
  title,
  element,
  parentErrorMessage,
  handleHousePartEdit,
}: IDashboardElement) => {
  const navigate = useNavigate();
  const parentErrorCount = parentErrorMessage?.length;
  const parentHasErrors = parentErrorCount ? "#C90202" : "#41F24F";
  const parentError = parentErrorCount
    ? parentErrorCount > 1
      ? parentErrorCount + " errors"
      : parentErrorMessage[0]
    : "Completed";
  return (
    <ColumnContainer ml={"80px"}>
      <ColumnContainer width={"100%"}>
        <Box display={"flex"} alignItems={"center"}>
          <SmallCircleBox sx={{ backgroundColor: parentHasErrors, mr: 2 }} />
          <Typography variant="body1">{title}</Typography>
          <MainButton
            text="EDIT"
            variant="text"
            icon={<EditIcon height="18px" width="18px" />}
            sx={{ ml: "auto" }}
            handleClick={handleHousePartEdit}
          />
        </Box>
        <Typography variant="subtitle1" ml={3}>
          {parentError}
        </Typography>
        {element &&
          element.map((windowFrame) => (
            <Box key={windowFrame.name} display={"flex"} alignItems={"start"} ml={3}>
              <LShape />
              <ColumnContainer width={"100%"}>
                <Box display={"flex"} alignItems={"center"}>
                  <SmallCircleBox
                    sx={{
                      backgroundColor: windowFrame.errors?.length ? "#C90202" : "#41F24F",
                      mr: "10px",
                    }}
                  />
                  <Typography variant="body1">{windowFrame.name}</Typography>
                  <MainButton
                    text="EDIT"
                    variant="text"
                    icon={<EditIcon height="18px" width="18px" />}
                    sx={{ ml: "auto" }}
                    handleClick={() => (windowFrame.path ? navigate(windowFrame?.path) : "")}
                  />
                </Box>
                <Typography variant="subtitle1" ml={2}>
                  {windowFrame?.errors?.length
                    ? windowFrame?.errors?.length > 1
                      ? windowFrame?.errors?.length + " errors"
                      : windowFrame?.errors[0]
                    : "Completed"}
                </Typography>
              </ColumnContainer>
            </Box>
          ))}
      </ColumnContainer>
    </ColumnContainer>
  );
};

interface IDashboardItem extends BoxProps {
  Icon:
    | FunctionComponent<IconBaseProps>
    | (OverridableComponent<SvgIconTypeMap<IconBaseProps, "svg">> & { muiName: string });
  alt: string;
  text: string;
}

export const DashboardItemContainer: FC<PropsWithChildren<IDashboardItem>> = ({
  Icon,
  alt,
  children,
  text,
  ...props
}) => {
  return (
    <ColumnContainer {...props}>
      <DashboardHeader>
        <DashboardHeaderIcon>
          {<Icon color="action" />}
        </DashboardHeaderIcon>
        <Typography>{text}</Typography>
      </DashboardHeader>
      {children}
    </ColumnContainer>
  );
};
