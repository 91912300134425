import { FC, useEffect } from "react";
import { Box, Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import WindowIcon from "../../assets/icons/window.svg";
import { MainButton } from "../../components/common/Button/MainButton";
import { Card } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { Cover } from "../../components/common/Cover/Cover";
import { NotesIcon, PlusIcon } from "../../components/common/Icons";
import {
  CardSectionWindows,
  SectionTitle,
} from "../../components/common/StepComponents/StepContainers";
import { DividerStack } from "../../components/common/StepComponents/StepContainers.styled";
import { useAppSelector } from "../../store/hooks";
import { getFilteredSuggestions } from "../../store/slices";
import {
  duplicateElement,
  getNotesData,
  getStoreyAreas,
  getWallsAndRoof,
} from "../../store/slices/inspectionSlice";
import { IDormer, IRoof, IWall, IWindow, IWindowGroup, SuggestionCategory } from "../../types";
import { Path, useNavigation } from "../../routes";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

export const Flat: FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const { t } = useTranslation();
  const windowSuggestionOwner = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.OWNER_WINDOW),
  );
  const windowSuggestionSupplier = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.EXECUTIVE_WINDOW),
  );

  const notes = useAppSelector(getNotesData);
  const { walls, roofs } = useAppSelector(getWallsAndRoof);
  const storeyAreas = useAppSelector(getStoreyAreas);

  const handleSave = async () => {
    const notesErr = notes.ownerWindows
      .map((el, index) => (el.length === 0 ? index : undefined))
      .filter(Boolean);
    if (notesErr.length) {
      const errMsg = `${t("mandatoryFieldNotes")} [${notesErr}] ${
        notesErr.length > 1 ? t("storeys").toLowerCase() : t("storey").toLowerCase()
      }`;
      toast.error(errMsg);
      return;
    }
    navigate(`/houses/${id}/roof`);
  };

  const handleDuplicate = (name: string, housePart: string, parentElement?: string) =>
    dispatch(duplicateElement({ name, housePart, parentElement }));

  const getWindowsErrCount = (arr: IWindow[]): number => {
    const errCount = arr.reduce<number>(
      (curr, next) => (curr += next.errors?.length ? next.errors.length : 0),
      0,
    );
    return errCount;
  };

  return (
    <MainPageWrapper>
      <Cover title={t("windows")} />
      <PageWrapper>
        {storeyAreas.map((storey) => (
          <Card sx={{ px: 0 }} key={storey}>
            <DividerStack divider={<Divider />} spacing={2}>
              <Box>
                <SectionTitle title={t("storey") + " " + storey}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <MainButton
                      icon={<NotesIcon color="black" width="20px" />}
                      text={t("notes")}
                      variant="white"
                      onClick={() => {
                        navigation(
                          Path.AddWindowGroupNotes,
                          { id, storeyId: storey },
                          {
                            state: {
                              windowSuggestionOwner,
                              windowSuggestionSupplier,
                              ownerWindowNotes: notes?.ownerWindows,
                              supplierWindowNotes: notes?.supplierWindows,
                            },
                          },
                        );
                      }}
                    />
                    <MainButton
                      icon={<PlusIcon color="white" width="10px" />}
                      text={t("add")}
                      variant="secondary"
                      onClick={() => {
                        navigation(Path.AddWindowGroup, { id, storeyId: storey });
                      }}
                    />
                  </Box>
                </SectionTitle>
              </Box>
              {walls?.map((wall) =>
                wall?.windowGroups?.map((windowFrame) => {
                  if (+windowFrame.storey === +storey) {
                    return (
                      <CardSectionWindows
                        errors={getWindowsErrCount(windowFrame.windows)}
                        key={windowFrame.name}
                        title={`${windowFrame.name} (${wall.name})`}
                        icon={WindowIcon}
                        windows={windowFrame.windows.length}
                        handleDuplicate={() => handleDuplicate(windowFrame.name, "wallWindowFrame")}
                        handleClick={() =>
                          navigation(Path.AddWindowWall, {
                            id,
                            wallId: wall.name,
                            flatId: windowFrame.name,
                          })
                        }
                      />
                    );
                  } else {
                    return null;
                  }
                }),
              )}
              {roofs?.map((roof) =>
                roof?.windowGroups?.map((windowFrame) => {
                  if (+windowFrame.storey === +storey) {
                    return (
                      <CardSectionWindows
                        errors={getWindowsErrCount(windowFrame.windows)}
                        key={windowFrame.name}
                        title={`${windowFrame.name} (${roof.name})`}
                        icon={WindowIcon}
                        windows={windowFrame.windows.length}
                        handleDuplicate={() => handleDuplicate(windowFrame.name, "roofWindowFrame")}
                        handleClick={() =>
                          navigation(Path.AddWindowRoof, {
                            id,
                            roofId: roof.name,
                            flatId: windowFrame.name,
                          })
                        }
                      />
                    );
                  } else {
                    return null;
                  }
                }),
              )}
              {roofs?.map((roof) =>
                roof?.dormers?.map((dormer) =>
                  dormer?.windowGroups?.map((windowFrame) => {
                    if (+windowFrame.storey === +storey) {
                      return (
                        <CardSectionWindows
                          errors={getWindowsErrCount(windowFrame.windows)}
                          key={windowFrame.name}
                          title={`${windowFrame.name} (${dormer.name})`}
                          icon={WindowIcon}
                          windows={windowFrame.windows.length}
                          handleDuplicate={() =>
                            handleDuplicate(windowFrame.name, "dormerWindowFrame", dormer.name)
                          }
                          handleClick={() =>
                            navigation(Path.AddWindowDormer, {
                              id,
                              dormerId: dormer.name,
                              flatId: windowFrame.name,
                            })
                          }
                        />
                      );
                    } else {
                      return null;
                    }
                  }),
                ),
              )}
            </DividerStack>
          </Card>
        ))}
        <MainButton
          text={t("save")}
          variant="primary"
          onClick={handleSave}
          sx={{ margin: "30px 0" }}
        />
      </PageWrapper>
    </MainPageWrapper>
  );
};
