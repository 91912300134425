import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Box, CardMedia, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Compressor from "compressorjs";
import { FC, useCallback, useEffect, useState } from "react";
import deleteIcon from "../../assets/icons/delete.svg";
import { useAppDispatch } from "../../store/hooks";
import { Card } from "../common/Container/Containers";
import { PhotoModal } from "./Modal";

import {
  AddPhotoBox,
  AddPhotoButton,
  AddPhotoEmptyContainer,
  ImageOverlay,
  PhotoBoxStyled,
  PhotosContainer,
} from "./Photos.styled";
import { deleteImage, fetchImage, uploadInspectionImage } from "../../store/thunks";
import { FreeFormSelect } from "../common/Form/FormSelect";
import { IImage } from "../../types/image";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useConfirm } from "../common/Dialog/ConfirmDialog";
import { useNetworkStatus } from "../../utils/hooks/networkStatus";

export const blobToBase64 = (blob: File | Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const convertBlobToBase64 = async (blob: File | Blob) => {
  return await blobToBase64(blob);
};

type PhotosProps = {
  tags: any;
  sphId: string;
  form: any;
};

export type ImageType = {
  image: string;
  filename: string;
  tag: string;
};

export const Photos: FC<PhotosProps> = ({ tags, sphId, form }) => {
  const isOnline = useNetworkStatus();
  const { t } = useTranslation();
  const [modalImg, setModalImg] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => setIsOpen(!isOpen);
  const dispatch = useAppDispatch();
  const [imageList, setImageList] = useState(form.getValues("images"));
  const confirm = useConfirm();
  const [images, setImages] = useState<ImageType[]>([]);
  const formImages = form.watch("images");

  useEffect(() => {
    setImageList(form.getValues("images"));
  }, [form, formImages]);

  const handleImage = async (imageArr: HTMLInputElement["files"]) => {
    /**
     * 1. Resize image
     * 2. Upload image to BE ( Show dynamic upload progress or just some animation? )
     * 3. Take reference path from response and store it together with tag in corresponding store slice
     *
     *  */
    if (!imageArr) return;
    for (let i = 0; i < imageArr?.length; i++) {
      const element = imageArr[i];
      new Compressor(element, {
        quality: 0.6,
        maxHeight: Infinity,
        maxWidth: Infinity,
        resize: "none",

        async success(file) {
          const b64 = (await convertBlobToBase64(file)) as string;

          if (file instanceof File) {
            dispatch(uploadInspectionImage({ sphId, image: file, filename: element.name }))
              .unwrap()
              .then(async (res: any) => {
                if (res.response.status !== 200) {
                  toast.error(t("failedUpload"));
                  return;
                }
                const newImage = { filename: res.filename, tag: "" };
                const formImages = form.getValues("images");
                const newImages = [...formImages, newImage];
                form.setValue("images", newImages);

                setImages((prevImages) => [
                  ...prevImages,
                  { filename: res.filename, image: b64, tag: "" },
                ]);
                toast.success(t("successfulUpload"));
              })
              .catch((rejectedValueOrSerializedError) => {
                toast.error(t("failedUpload"));
              });
          } else {
            const compressedFileFromBlob = new File([file], element.name, {
              type: file.type,
            });

            dispatch(
              uploadInspectionImage({
                sphId,
                image: compressedFileFromBlob,
                filename: element.name,
              }),
            )
              .unwrap()
              .then(async (res) => {
                if (!res) {
                  toast.error(t("failedUpload"));
                  return;
                }
                const newImage = { filename: res.imgName, tag: "" };
                const formImages = form.getValues("images");
                const newImages = [...formImages, newImage];
                form.setValue("images", newImages);

                setImages((prevImages) => [
                  ...prevImages,
                  { filename: res.imgName, image: b64, tag: "" },
                ]);
                toast.success(t("successfulUpload"));
              })
              .catch((rejectedValueOrSerializedError) => {
                if (!isOnline || rejectedValueOrSerializedError.code === "ERR_NETWORK") {
                  toast.error(t("networkFailure"));
                  return;
                }
                toast.error(t("failedUpload"));
              });
          }
        },
      });
    }
  };

  const getImages = useCallback(async () => {
    imageList?.forEach(async (element: IImage) => {
      const data = await fetchImage(+sphId, element);
      const blobToImg: string = data ? ((await convertBlobToBase64(data.file)) as string) : "";
      if (data) {
        setImages((prevImages) => [
          ...prevImages,
          { filename: data.filename, tag: data.tag, image: blobToImg },
        ]);
      }
    });
  }, []);

  useEffect(() => {
    getImages();
  }, []);

  const onDeleteImage = async (e: React.MouseEvent<HTMLImageElement>, imageName: string) => {
    e.stopPropagation();
    const choice = await confirm({
      title: t("delete"),
      description: t("deletePicture"),
      confirmBtnLabel: t("delete"),
    });

    if (choice) {
      const filteredImages = imageList.filter((image: IImage) => image.filename !== imageName);
      setImages(images.filter((image) => image.filename !== imageName));
      form.setValue("images", filteredImages);
      await deleteImage(+sphId, imageName);
      toast.success(t("success"));
    }
  };

  const handleTag = async (tag: string, filename: string) => {
    const mappedImages = imageList.map((image: ImageType) => {
      if (image.filename === filename) {
        return { filename: image.filename, tag };
      } else {
        return image;
      }
    });
    form.setValue("images", mappedImages);
  };

  return (
    <Grid container columns={12}>
      <Card width="100%" sx={{ border: images.length ? "transparent" : "2px solid #A21313" }}>
        <Typography variant="h3">
          {t("pictures")} {images.length > 0 && <>({images.length})</>}
        </Typography>
        <Grid item mt={2}>
          {images.length > 0 ? (
            <PhotosContainer>
              {images.map((image, index: number) => {
                return (
                  <PhotoBoxStyled p={2} key={index}>
                    <CardMedia
                      component="img"
                      image={image.image as unknown as string}
                      loading="eager"
                      alt={image.filename as string}
                    />
                    <ImageOverlay
                      onClick={(element) => {
                        const prevSibling = element.currentTarget
                          .previousSibling as HTMLImageElement;
                        const src = prevSibling?.src;
                        if (src) {
                          setModalImg(src);
                          handleModal();
                        }
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="delete"
                        onClick={(event) => onDeleteImage(event, image.filename)}
                        id={`${index}`}
                      />
                    </ImageOverlay>
                    <Box bgcolor="#405360" p={1} height="100%">
                      <FreeFormSelect
                        options={tags}
                        label={t("selectTag")}
                        defaultValue={image.tag || ""}
                        sx={{
                          width: "100%",
                          ".MuiInputBase-root": { background: "white" },
                          ".MuiInputLabel-shrink": { top: "8px" },
                        }}
                        onChange={(event) => handleTag(event.target.value, image.filename)}
                      />
                    </Box>
                  </PhotoBoxStyled>
                );
              })}
              <Box>
                <AddPhotoButton
                  component={"label"}
                  startIcon={
                    <AddPhotoBox>
                      <AddAPhotoIcon />
                      <Typography variant="body1">{t("addPicture")}</Typography>
                    </AddPhotoBox>
                  }
                >
                  <input
                    multiple
                    type="file"
                    accept="image/jpeg"
                    style={{ display: "none" }}
                    onChange={(e) => handleImage(e.target.files)}
                  />
                </AddPhotoButton>
              </Box>
            </PhotosContainer>
          ) : (
            <AddPhotoEmptyContainer>
              <AddPhotoButton
                component={"label"}
                startIcon={
                  <AddPhotoBox>
                    <AddAPhotoIcon />
                    <Typography variant="subtitle1">{t("addPicture")}</Typography>
                  </AddPhotoBox>
                }
              >
                <input
                  multiple
                  type="file"
                  accept="image/jpeg"
                  style={{ display: "none" }}
                  onChange={(e) => handleImage(e.target.files)}
                />
              </AddPhotoButton>
              {images.length > 0 && <p>{t("imageTagError")}</p>}
            </AddPhotoEmptyContainer>
          )}
        </Grid>
      </Card>
      <PhotoModal imageSrc={modalImg} isOpen={isOpen} handlePhotoModal={handleModal} />
    </Grid>
  );
};
