import { yupResolver } from "@hookform/resolvers/yup";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import NetworkStatus from "src/components/NetworkStatus/NetworkStatus";
import BackButton from "src/components/common/Button/BackButton";
import { MainButton } from "src/components/common/Button/MainButton";
import { ScrollToTop } from "src/components/common/ScrollToTop";
import { getSummaryPageSchema } from "src/types/schema";
import type { InferType } from "yup";
import logo from "../../assets/icons/logo.png";
import { BlueBgPageWrapper } from "../../components/common/Container/BlueWrapper";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  getCompletedInspection,
  getOwnerPreferencesData,
  getSummaryData,
  getWindowsSummary,
  saveOwnerPreferences,
} from "../../store/slices";
import { SummaryCard } from "./SummaryCard";
import { WindowSummaryCard } from "./WindowSummaryCard";
import { saveInspection } from "src/store/thunks";
import { Path } from "src/routes";

export const Summary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const inspection = useAppSelector(getCompletedInspection);
  const summaryData = useAppSelector(getSummaryData());
  const windowsSummary = useAppSelector(getWindowsSummary());
  const ownerPreferences = useAppSelector(getOwnerPreferencesData);
  const SummarySchema = getSummaryPageSchema(t);

  type SummarySchemaType = InferType<typeof SummarySchema>;

  const form = useForm<SummarySchemaType>({
    resolver: yupResolver(SummarySchema),
    mode: "all",
    shouldUnregister: false,
    defaultValues: {
      floor: {
        preference: ownerPreferences?.floor?.preference,
        reason: ownerPreferences?.floor?.reason,
      },
      wall: {
        preference: ownerPreferences?.wall?.preference,
        reason: ownerPreferences?.wall?.reason,
      },
      roof: {
        reason: ownerPreferences?.roof?.reason,
        preference: ownerPreferences?.roof?.preference,
      },
      solar: {
        reason: ownerPreferences?.solar?.reason,
        preference: ownerPreferences?.solar?.preference,
      },
      windows: ownerPreferences?.windows?.map((el) => {
        return { reason: "" || el.reason, preference: "" || el.preference };
      }),
    },
  });

  const {
    trigger,
    formState: { errors },
    getValues,
  } = form;

  useEffect(() => {
    trigger();
  }, []);

  const handleSave = async () => {
    trigger();
    const housePartErrors = Object.entries(errors);
    if (housePartErrors.length > 0) {
      if (Object.keys(housePartErrors[0][1])[0] === "preference") {
        toast.error(`${t("requiredField")}: ${t(housePartErrors[0][0])} ${t("preference")}`);
        return document
          .querySelector(`div[class*=parent-${housePartErrors[0][0]}]`)
          ?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
      } else {
        toast.error(`${t("requiredField")}: ${t(housePartErrors[0][0])} ${t("reason")}`);
        return document
          .querySelector(`div[class*=parent-${housePartErrors[0][0]}]`)
          ?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
      }
    }
    const ownerPreferences = getValues();
    dispatch(saveOwnerPreferences(ownerPreferences));
    const res = await dispatch(saveInspection({ inspection }));
    if (res.meta.requestStatus === "rejected") {
      return toast.error(t("inspectionSaveFail"));
    }
    toast.success(t("inspectionSaveSuccess"));
    navigate(Path.InspectionComplete, { state: { id: inspection.sphId } });
  };

  return (
    <MainPageWrapper>
      <ScrollToTop />
      <BlueBgPageWrapper>
        <Box sx={{ position: "absolute", left: "10px" }}>
          <img src={logo} width="70px" alt={"DeWoonpas"} />
          <BackButton props={{ position: "relative", left: "1rem" }} />
        </Box>
        <Paper elevation={0} sx={{ margin: "5rem 4rem 2rem 5rem", borderRadius: "15px" }}>
          <NetworkStatus />
          <Box
            sx={{ display: "flex", marginTop: "2rem", marginLeft: "2rem", alignItems: "center" }}
          >
            <CommentOutlinedIcon fontSize="small" sx={{ marginRight: "1rem" }} />
            <Typography variant="h5" component={"h1"}>
              {t("summary")}
            </Typography>
          </Box>
          <FormProvider {...form}>
            {[...summaryData.entries()].map((el) => (
              <SummaryCard title={el[0]} key={el[0]} elementSummary={el[1]} />
            ))}
            {windowsSummary.map((storey, index) => (
              <WindowSummaryCard
                key={`${index}_${storey?.windowGroups?.length}`}
                title={storey.title}
                storeyOwnerAgreements={storey.ownerAgreements}
                windowSummary={storey.windowGroups}
                index={index}
              />
            ))}
          </FormProvider>
          <Box sx={{ mx: 4, my: 8, display: "flex", justifyContent: "center" }}>
            <MainButton
              onClick={handleSave}
              variant="primary"
              text={`${t("send")} ${t("inspection")}`}
            />
          </Box>
        </Paper>
      </BlueBgPageWrapper>
    </MainPageWrapper>
  );
};
