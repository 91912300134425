import { Direction, IImage, IProduct, IWindowGroup } from ".";

export enum Condition {
  BAD = "slecht",
  MEDIOCRE = "matig",
  REASONABLE = "redelijk",
  GOOD = "goed",
}

export enum brickType {
  PROBLEM_BRICKTYPE = "geen probleem",
  BLINDING = "verblend",
  STRAND_PRESS_BRICK = "strengpers",
  GLAZED = "geglazuurd",
  MBI = "MBI",
}

export enum CavityPolution {
  NONE = "geen",
  LIGHT = "licht",
  STRONG = "sterk",
}

export enum WallInsulationMaterial {
  NONE = "geen",
  UNKNOWN = "onbekend",
  MINERAL_WOOL = "minerale wol",
  UF_FOAM = "UF schuim",
  TEMPEX = "tempex",
  FOIL = "folie",
}

export enum JointType {
  RIGHT = "vlak",
  SNAP = "knip",
  CUT = "gesneden",
  RECLINING = "terugliggend",
}

export enum WallDrillDiameter {
  SMALL = "14mm",
  MEDIUM = "16mm",
  BIG = "18mm",
}

export interface IWall {
  name: string;
  notes: string;
  constructionYear: number;
  direction: Direction;
  area: number;
  windLoadedLength: number;
  doorCount: number;
  wallCondition: Condition;
  brickType: brickType;
  cavityWidth: number;
  cavityPollution: CavityPolution;
  insulationMaterial: WallInsulationMaterial;
  insulationThickness: number;
  jointCondition: Condition;
  jointType: JointType;
  ventilationGrilleCount: number;
  hasExpansionJoint: boolean;
  hasCrack: boolean;
  hasVaporBarier: boolean;
  hasVegetation: boolean;
  windowGroups: IWindowGroup[];
  images: IImage[];

  measure: {
    insulationProduct: IProduct | null;
    insulationThickness: number;
    ventilationGrilleCount: number;
  };
  errors?: string[] | [];
}
