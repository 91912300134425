import { styled } from "@mui/material/styles";
import { FC } from "react";

export type IconBaseProps = {
  opacity?: number;
  color?: string;
  width?: string;
  height?: string;
};

const IconBase = styled("svg")<IconBaseProps>`
  ${({ width }) => (width ? `width: ${width};` : "")}
  ${({ height }) => (height ? `height: ${height};` : "")}
  ${({ color }) => (color ? `fill: ${color};` : "")}
  ${({ opacity }) => (opacity ? `opacity: ${opacity};` : "")}
`;

export const PlusIcon: FC<IconBaseProps> = (props) => (
  <IconBase viewBox="0 0 10 10" {...props}>
    <path d="M4.5 0C4.22386 0 4 0.223858 4 0.5V4H0.5C0.223858 4 0 4.22386 0 4.5V5.5C0 5.77614 0.223858 6 0.5 6H4V9.5C4 9.77614 4.22386 10 4.5 10H5.5C5.77614 10 6 9.77614 6 9.5V6H9.5C9.77614 6 10 5.77614 10 5.5V4.5C10 4.22386 9.77614 4 9.5 4H6V0.5C6 0.223858 5.77614 0 5.5 0H4.5Z" />
  </IconBase>
);

export const AddPhotoIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 -960 960 960" {...props}>
    <path d="M773-654v-86h-86v-60h86v-87h60v87h87v60h-87v86h-60ZM94-80q-24 0-42-18t-18-42v-513q0-23 18-41.5T94-713h147l73-87h280v60H342l-73 87H94v513h680v-420h60v420q0 24-18.5 42T774-80H94Zm339.5-146q72.5 0 121.5-49t49-121.5q0-72.5-49-121T433.5-566q-72.5 0-121 48.5t-48.5 121q0 72.5 48.5 121.5t121 49Zm0-60q-47.5 0-78.5-31.5t-31-79q0-47.5 31-78.5t78.5-31q47.5 0 79 31t31.5 78.5q0 47.5-31.5 79t-79 31.5Zm.5-110Z" />
  </IconBase>
);

export const ArrowBackIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 -960 960 960" {...props}>
    <path d="M480-160 160-480l320-320 42 42-248 248h526v60H274l248 248-42 42Z" />
  </IconBase>
);

export const DeleteIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 -960 960 960" {...props}>
    <path d="M261-120q-24.75 0-42.375-17.625T201-180v-570h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438v-570ZM367-266h60v-399h-60v399Zm166 0h60v-399h-60v399ZM261-750v570-570Z" />
  </IconBase>
);

export const EditIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" fill="#54C1F0" viewBox="0 -960 960 960" {...props}>
    <path d="M794-666 666-794l42-42q17-17 42.5-16.5T793-835l43 43q17 17 17 42t-17 42l-42 42Zm-42 42L248-120H120v-128l504-504 128 128Z" />
  </IconBase>
);

export const ExploreIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" fill="#54C1F0" viewBox="0 -960 960 960" {...props}>
    <path d="m263-263 290-143 143-290-290 143-143 290Zm217-177q-17 0-28.5-11.5T440-480q0-17 11.5-28.5T480-520q17 0 28.5 11.5T520-480q0 17-11.5 28.5T480-440Zm0 360q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" />
  </IconBase>
);

export const FloorIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 -960 960 960" {...props}>
    <path d="M480-130 120-410l50-37 310 241 310-241 50 37-360 280Zm0-152L120-562l360-280 360 280-360 280Zm0-301Zm0 225 262-204-262-204-262 204 262 204Z" />
  </IconBase>
);

export const HouseIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 -960 960 960" {...props}>
    <path d="M220-180h150v-250h220v250h150v-390L480-765 220-570v390Zm-60 60v-480l320-240 320 240v480H530v-250H430v250H160Zm320-353Z" />
  </IconBase>
);

export const OverviewIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 -960 960 960" {...props}>
    <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-414q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19ZM226-668q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 0q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Z" />
  </IconBase>
);

export const GeneralInfo: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 0 24 24" {...props}>
    <path d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
  </IconBase>
);

export const RoofIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 -960 960 960" {...props}>
    <path d="m80-480 400-360 200 180v-120h80v192l120 108h-89L480-759 169-480H80Zm290 320v-220h220v220H370Zm60-60h100v-100H430v100Zm50-50Z" />
  </IconBase>
);

export const SolarIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 -960 960 960" {...props}>
    <path d="m80-80 80-400h640l80 400H80Zm40-740v-60h120v60H120Zm34 680h296v-110H176l-22 110Zm80-460-43-42 85-85 43 42-85 85Zm-46 290h262v-110H210l-22 110Zm292-390q-74 0-125-52.5T300-880h60q3 50 37 85t83 35q49 0 83-35t37-85h60q-4 75-55 127.5T480-700Zm0-180Zm-30 360v-120h60v120h-60Zm60 380h296l-22-110H510v110Zm0-170h262l-22-110H510v110Zm216-291-85-85 42-42 86 84-43 43Zm-6-219v-60h120v60H720Z" />
  </IconBase>
);

export const StraightenIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 -960 960 960" {...props}>
    <path d="M140-240q-24 0-42-18t-18-42v-360q0-23 18-41.5t42-18.5h680q24 0 42 18.5t18 41.5v360q0 24-18 42t-42 18H140Zm0-60h680v-360H690v180h-60v-180H510v180h-60v-180H330v180h-60v-180H140v360Zm130-180h60-60Zm180 0h60-60Zm180 0h60-60Zm-150 0Z" />
  </IconBase>
);

export const WindowIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 -960 960 960" {...props}>
    <path d="M120-510v-330h330v330H120Zm0 390v-330h330v330H120Zm390-390v-330h330v330H510Zm0 390v-330h330v330H510ZM180-570h210v-210H180v210Zm390 0h210v-210H570v210Zm0 390h210v-210H570v210Zm-390 0h210v-210H180v210Zm390-390Zm0 180Zm-180 0Zm0-180Z" />
  </IconBase>
);

export const NotesIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 0 24 24" {...props}>
    <path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
  </IconBase>
);

export const LogoutIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 -960 960 960" {...props}>
    <path d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h291v60H180v600h291v60H180Zm486-185-43-43 102-102H375v-60h348L621-612l43-43 176 176-174 174Z" />
  </IconBase>
);

export const MapIcon: FC<IconBaseProps> = (props) => (
  <IconBase width="27" height="27" viewBox="0 0 24 24" {...props}>
    <path d="m20.5 3-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM10 5.47l4 1.4v11.66l-4-1.4V5.47zm-5 .99 3-1.01v11.7l-3 1.16V6.46zm14 11.08-3 1.01V6.86l3-1.16v11.84z" />
  </IconBase>
);
