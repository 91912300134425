import { Stack } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import WindowIcon from "../../assets/icons/window.svg";
import { AccordionContainer } from "../../components/common/Accordion/Accordion";
import { MainButton } from "../../components/common/Button/MainButton";
import { Card } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { Cover } from "../../components/common/Cover/Cover";
import { Form } from "../../components/common/Form/Form";
import { FormInput } from "../../components/common/Form/FormInput";
import { FormSelect } from "../../components/common/Form/FormSelect";
import { FormStack } from "../../components/common/Form/FormStack";
import {
  AdditionalData,
  AdditionalDataWrapper,
} from "../../components/common/StepComponents/AdditionalData";
import {
  EmptyContainer,
  SectionTitle,
} from "../../components/common/StepComponents/StepContainers";
import { Photos } from "../../components/photos/Photos";
import { useAppSelector } from "../../store/hooks";
import {
  addWindowGroup,
  deleteWindowGroup,
  editWindowGroup,
  getFilteredImageTags,
  getFilteredSuggestions,
  getRoofsData,
  getRoofsWallsDormers,
  getStoreyAreas,
  getWalls,
  getWindowFrameByName,
} from "../../store/slices";
import { FrameCondition, FrameType, Category, SuggestionCategory, IWindowGroup } from "../../types";
import { getAddWindowFrameSchema } from "../../types/schema";
import { WindowFrame } from "../Window/WindowFrame";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { useConfirm } from "../../components/common/Dialog/ConfirmDialog";

export const AddWindow: FC = () => {
  const { id, wallId, dormerId, roofId, flatId, storeyId } = useParams();
  const { t } = useTranslation();

  const confirm = useConfirm();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const tags = useAppSelector(getFilteredImageTags(Category.WINDOW_GROUP));
  const storeyAreas = useAppSelector(getStoreyAreas);

  const windowSuggestionExecutive = useAppSelector(
    getFilteredSuggestions(SuggestionCategory.EXECUTIVE_WINDOW),
  );
  const wallData = useAppSelector(getWalls);
  const roofData = useAppSelector(getRoofsData);
  const parentElements = useAppSelector(getRoofsWallsDormers);

  const isExpanded = (panel: string) => expanded === panel;

  const handleChange = (panel: string) => setExpanded(isExpanded(panel) ? false : panel);
  const windowFrame = useAppSelector<IWindowGroup | undefined>((state: RootState) =>
    getWindowFrameByName(state, flatId || "", roofId, wallId, dormerId),
  );

  const form = useForm({
    resolver: yupResolver(getAddWindowFrameSchema(t)),
    mode: "all",
    defaultValues: {
      ...windowFrame,
      name: windowFrame?.name || "",
      notes: windowFrame?.notes || "",
      storey: windowFrame?.storey || storeyId || storeyAreas[0] || 0,
      parentElement: wallId
        ? "[Muren] " + wallId
        : undefined || roofId
        ? "[Daken] " + roofId
        : undefined || dormerId
        ? "[Dakkapellen] " + dormerId
        : undefined,
      frameType: windowFrame?.frameType || "",
      frameCondition: windowFrame?.frameCondition || "",
      windows: windowFrame?.windows || [],
      images: windowFrame?.images || [],
    },
  });

  const {
    formState: { errors },
    register,
    trigger,
    watch,
  } = form;
  const name = watch("name");
  const parentElement = watch("parentElement");

  const windows = watch("windows");
  const [expanded, setExpanded] = useState<string | boolean>(windows[0]?.name);

  const addWindow = () => {
    const windows = form.getValues("windows");
    let windowName = (windows.length + 1).toString();
    const uniqueName = windows.find((window) => window.name === windowName.toString());
    if (uniqueName) {
      windowName = t("window") + " " + windowName;
    }

    const newWindow = {
      name: windowName,
      area: 0,
      glassType: undefined,
      hasVentilationGrille: false,
      headThickness: 0,
      measure: {
        glassProduct: {
          id: "0",
          name: undefined || "",
          category: Category.WINDOW_GROUP,
        },
        ventilationGrilleWidth: 0,
      },
      windowType: undefined,
    };

    windows.push(newWindow);
    form.setValue("windows", windows);
    setExpanded(newWindow.name);
  };

  const handleSave = async () => {
    await form.trigger();

    if (
      !form.getValues("name") ||
      !form.getValues("frameType") ||
      !form.getValues("frameCondition") ||
      !form.getValues("parentElement")
    ) {
      toast.error("Fill in the mandatory fields");
      form.setFocus("name");
      return;
    }

    let isNameUnique = true;
    const isWall = parentElement?.includes("[Muren]") || false;
    const isRoof = parentElement?.includes("[Daken]") || false;
    const isDormer = parentElement?.includes("[Dakkapellen]") || false;

    if (isWall && !flatId) {
      wallData?.forEach((wall) => {
        wall?.windowGroups?.forEach((group) => {
          if (group.name === name) {
            isNameUnique = false;
          }
        });
      });
    }
    if (isRoof && !flatId) {
      roofData?.forEach((roof) => {
        roof?.windowGroups?.forEach((group) => {
          if (group.name === name) {
            isNameUnique = false;
          }
        });
      });
    }
    if (isDormer && !flatId) {
      roofData?.forEach((roof) => {
        roof?.dormers?.forEach((dormer) => {
          if (dormer.name === name) {
            dormer?.windowGroups?.forEach((group) => {
              if (group.name === name) {
                isNameUnique = false;
              }
            });
          }
        });
      });
    }

    if (!isNameUnique) {
      toast.error(t("uniqueName"));
      form.setFocus("name");
      return;
    }

    if (flatId) {
      dispatch(editWindowGroup({ ...form.getValues(), wallId, roofId, dormerId, flatId }));
    } else {
      dispatch(addWindowGroup({ ...form.getValues(), wallId, roofId, dormerId, flatId }));
    }

    navigate(`/houses/${id}/flat`);
  };

  const handleDelete = async () => {
    const choice = await confirm({
      title: t("delete"),
      description: t("deleteWindowGroup"),
      confirmBtnLabel: t("delete"),
    });

    if (choice) {
      dispatch(deleteWindowGroup({ wallId, roofId, dormerId, flatId }));
      navigate(-1);
    }
  };

  const handleDuplicateWindow = (name: string, id: number) => {
    const elementToDuplicate = windows.at(id);
    const windowsArr = windows.length;
    if (elementToDuplicate) {
      const windowName = elementToDuplicate.name.includes("kopie")
        ? elementToDuplicate.name.replace(/\(kopie_\d\)/i, "")
        : elementToDuplicate.name;
      const duplicatedWindow = { ...elementToDuplicate };
      duplicatedWindow.name = `${windowName} (kopie${windowsArr})`;
      windows.push(duplicatedWindow);
      form.setValue("windows", windows);
    }
  };

  useEffect(() => {
    if (!windowFrame && !!flatId) navigate(`/houses/${id}/flat`);
    trigger();
  }, [trigger, windows, form]);

  return (
    <MainPageWrapper>
      <Cover
        title={t("addWindowFrame")}
        showBackButton
        handleDelete={flatId ? handleDelete : undefined}
      />
      <PageWrapper>
        <Form>
          <Card>
            <Stack spacing={2} width="50%">
              <SectionTitle variant="h2" title={t("general")} />
              <FormStack>
                <FormInput {...register("name")} label={t("name")} error={errors.name} />
                <FormSelect
                  name="frameType"
                  form={form}
                  label={t("frameType")}
                  options={Object.values(FrameType)}
                  error={errors.frameType}
                />
                <FormSelect
                  name="frameCondition"
                  form={form}
                  label={t("frameCondition")}
                  options={Object.values(FrameCondition)}
                  error={errors.frameCondition}
                />
                <FormSelect
                  name="parentElement"
                  form={form}
                  label={t("parentElement")}
                  options={parentElements}
                  error={errors.parentElement}
                />
              </FormStack>
            </Stack>
          </Card>

          {windows && windows.length === 0 ? (
            <EmptyContainer title={t("noWindowsAdded")} />
          ) : (
            <Card>
              <Stack spacing={2}>
                {windows &&
                  windows.length > 0 &&
                  windows?.map((window, id) => (
                    <AccordionContainer
                      key={uuid()}
                      title={window?.name}
                      panel={window?.name}
                      errors={window.errors || []}
                      expanded={isExpanded(window?.name)}
                      handleChange={handleChange}
                      handleDuplicate={() => handleDuplicateWindow(window?.name, id)}
                    >
                      <WindowFrame form={form} index={id} collapse={handleChange} />
                    </AccordionContainer>
                  ))}
              </Stack>
            </Card>
          )}

          <EmptyContainer title={t("addNewWindow")} icon={WindowIcon} action={addWindow} />

          <AdditionalDataWrapper title={t("general") || ""}>
            <AdditionalData
              name="notes"
              form={form}
              suggestions={windowSuggestionExecutive}
              error={errors.notes}
            />
          </AdditionalDataWrapper>
          <Photos tags={tags} sphId={id || ""} form={form} />
          <MainButton
            text={t("save")}
            variant="primary"
            onClick={handleSave}
            sx={{ margin: "30px 0" }}
          />
        </Form>
      </PageWrapper>
    </MainPageWrapper>
  );
};
