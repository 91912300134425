import { IProduct } from "./product";

export interface IHouse {
  sphId?: string;
  municipality: string;
  city: string;
  streetName: string;
  houseNumber: number;
  houseNumberAdd: string;
  postalCode: string;
  status: string;
  hasInspection: boolean;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
}

export enum SummaryAgreement {
  ACCEPT = "accept",
  DOUBT = "doubt",
  DECLINE = "decline",
}

export enum SummaryElements {
  Solar = "solar",
  Roof = "roof",
  Walls = "wall",
  Floor = "floor",
}

export type SummaryData = {
  elements: {
    name: string;
    insulation?: IProduct | null;
    soilInsulation?: IProduct | null;
    panelCount?: number;
  }[];
  ownerAgreements: string;
  atticFloor?: boolean;
};

export type WindowSummaryData = {
  ownerAgreements: string;
  windowGroups: {
    windowFrame: string;
    windowsCount: number;
    insulation: boolean;
  }[];
};
