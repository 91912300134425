import { IFormContext } from "../../pages/Houses/HouseInfo";
import { FC, useEffect } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IRoof } from "../../types";
import { FormInput } from "./Form/FormInput";
import { FormStack } from "./Form/FormStack";

export type SurfaceAreaProps = {
  form: UseFormReturn<IRoof & SurfaceAreaForm, any>;
};

export type SurfaceAreaForm = {
  width: number;
  height: number;
};

export const SurfaceArea: FC<any> = ({ form, isFloor }) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = form;
  const width = watch("width");
  const height = watch("height");

  const calculateSurfaceArea = (width: number, height: number): number => {
    const widthInMeters = width;
    const heightInMeters = height;
    const areaInSquareMeters = widthInMeters * heightInMeters;

    return areaInSquareMeters;
  };
  const { t } = useTranslation();
  
  useEffect(() => {
    const calculatedValue = calculateSurfaceArea(width, height);
    setValue("area", calculatedValue);
  }, [width, height, setValue]);

  return (
    <FormStack pt={2}>
      <FormInput {...register("width")} label={isFloor ? t("lengthM") : t("widthM")} />
      <FormInput {...register("height")} label={t("heightM")} />
    </FormStack>
  );
};

export const FloorAreas: FC<{ index: number }> = ({ index }) => {
  const { t } = useTranslation();
  
  const form = useFormContext<IFormContext>();
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = form;
  const width = watch(`floorAreas.${index}.width`) || 0;
  const height = watch(`floorAreas.${index}.height`) || 0;

  const calculateSurfaceArea = (width: number, height: number): number => {
    const widthInMeters = width;
    const heightInMeters = height;
    const areaInSquareMeters = widthInMeters * heightInMeters;

    return areaInSquareMeters;
  };

  useEffect(() => {
    const calculatedValue = calculateSurfaceArea(width, height);
    setValue(`floorAreas.${index}.area`, calculatedValue);
  }, [width, height, setValue, index]);

  return (
    <FormStack pt={2}>
      <FormInput
        {...register(`floorAreas.${index}.width`)}
        label={t("widthM")}
        error={errors.floorAreas && errors.floorAreas[index]?.width}
      />
      <FormInput
        {...register(`floorAreas.${index}.height`)}
        label={t("heightM")}
        error={errors.floorAreas && errors.floorAreas[index]?.height}
      />
    </FormStack>
  );
};
