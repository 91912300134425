import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getHouses } from "../../store/slices";
import { useAppSelector } from "../../store/hooks";
import { IHouse } from "../../types";

export const useFilteredHouse = () => {
  const [filteredHouse, setFilteredHouse] = useState<IHouse | null>(null);
  const { id } = useParams();
  const houses = useAppSelector(getHouses);

  useEffect(() => {
    if (houses.length !== 0) {
      const filtered = houses.find((house) => house?.sphId?.toString() === id);

      if (filtered) {
        setFilteredHouse(filtered);
      } else {
        setFilteredHouse(null);
      }
    }
  }, [houses, id]);

  return filteredHouse;
};