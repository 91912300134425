import Axios from "axios";
import localStorage from "../utils/localStorage";
const apiUrl = process.env.REACT_APP_API_URL;

export const api = Axios.create({
  baseURL: `${apiUrl}`,
});

api.interceptors.request.use(
  (req) => {
    const username = localStorage.getUsername();
    const token = localStorage.getToken();

    if (username && token) {
      req.auth = {
        username,
        password: token,
      };
    }

    return req;
  },
  (error) => {
    return Promise.reject(error);
  },
);
