import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/common/Container/Containers";
import { MainPageWrapper } from "../../components/common/Container/MainPage";
import { PageWrapper } from "../../components/common/Container/PageWrapper";
import { Cover } from "../../components/common/Cover/Cover";
import { Form } from "../../components/common/Form/Form";
import { FormInput } from "../../components/common/Form/FormInput";
import { FormSelect } from "../../components/common/Form/FormSelect";
import { FormStack } from "../../components/common/Form/FormStack";
import { Photos } from "../../components/photos/Photos";
import { useAppSelector } from "../../store/hooks";
import {
  deleteDormer,
  editDormer,
  getDormerByName,
  getDormers,
  getFilteredImageTags,
  getRoofsData,
  saveDormer,
} from "../../store/slices";
import { RootState } from "../../store/store";
import { DormerType, IDormer, Category, InsulationCondition } from "../../types";
import { getDormerSchema } from "../../types/schema";
import { useDispatch } from "react-redux";
import { MainButton } from "../../components/common/Button/MainButton";
import isNameUnique from "../../utils/helpers/nameChecker";
import { useConfirm } from "../../components/common/Dialog/ConfirmDialog";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import stringToNumber from "../../utils/helpers/strToNum";

export const AddDormer: FC = () => {
  const { t } = useTranslation();
  const { dormerId, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const sphId = id ? id : "";

  const roofElements = useSelector(getRoofsData);
  const dormers = useSelector(getDormers);
  const dormer = useAppSelector((state: RootState) => getDormerByName(state, dormerId || ""));

  const formDefaultValues = {
    ...dormer,
    insulationCondition: dormer?.insulationCondition,
    dormerType: dormer?.dormerType,
    roofName: dormer?.roofName,
    windowGroups: dormer?.windowGroups || [],
    images: dormer?.images || []
  };

  const tags: any = useAppSelector(getFilteredImageTags(Category.WINDOW_GROUP));

  const form = useForm<IDormer & { roofName: string }>({
    resolver: yupResolver(getDormerSchema(t)),
    mode: "all",
    defaultValues: formDefaultValues,
  });

  const {
    register,
    formState: { errors },
    trigger,
    watch,
  } = form;

  const name = watch("name");

  const handleSave = async () => {
    await trigger();

    if ("name" in form.formState.errors) {
      toast.error(t("mandatoryField"));
      form.setFocus("name");
      return;
    }

    if ("roofName" in form.formState.errors) {
      toast.error(t("mandatoryFieldRoofName"));
      form.setFocus("roofName");
      return;
    }

    if (form.getValues("name") !== dormerId && isNameUnique(dormers, name)) {
      toast.error(t("uniqueName"));
      form.setFocus("name");
      return;
    }

    if (dormerId) {
      dispatch(
        editDormer({
          ...form.getValues(),
          errors: Object.keys(form.formState.errors),

          dormerId,
        }),
      );
    } else {
      dispatch(saveDormer({ ...form.getValues(), errors: Object.keys(form.formState.errors) }));
    }

    toast.success(t("success"));
    navigate(-1);
  };

  useEffect(() => {
    if (!dormer && !!dormerId) navigate(`/houses/${id}/roof`);
    trigger();
  }, [trigger]);

  const handleDelete = async () => {
    const choice = await confirm({
      title: t("delete"),
      description: t("deleteDormer"),
      confirmBtnLabel: t("delete"),
    });

    if (choice) {
      dispatch(deleteDormer({ dormerId }));
      navigate(-1);
    }
  };

  return (
    <MainPageWrapper>
      <Cover
        title={dormerId ? t("editDormer") : t("addDormer")}
        showBackButton
        handleDelete={dormerId ? handleDelete : undefined}
      />
      <PageWrapper>
        <Form mt={5}>
          <Card>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              pb={2}
            >
              <Typography variant="h2" fontWeight={600}>
                {t("currentSituation")}
              </Typography>
            </Box>
            <FormStack direction="row">
              <Stack width="100%" spacing={3}>
                <FormInput {...register("name")} label={t("name")} error={errors.name} />
                <FormInput
                  {...register("length", { setValueAs: (v) => stringToNumber(v) })}
                  label={t("lengthM")}
                  error={errors.length}
                />
                <FormInput
                  {...register("height", { setValueAs: (v) => stringToNumber(v) })}
                  label={t("heightM")}
                  error={errors.height}
                />
                <FormInput
                  {...register("width", { setValueAs: (v) => stringToNumber(v) })}
                  label={t("widthM")}
                  error={errors.width}
                />
                <FormSelect
                  name="roofName"
                  form={form}
                  label={t("selectRoofElement")}
                  options={roofElements?.map((roof) => roof.name) || []}
                  error={errors.roofName}
                  value={form.getValues("roofName")}
                />
              </Stack>

              <Stack width="100%" spacing={3}>
                <FormSelect
                  name="dormerType"
                  form={form}
                  label={t("dormerType")}
                  options={Object.values(DormerType)}
                  error={errors.dormerType}
                  value={form.getValues("dormerType")}
                />
                <FormSelect
                  name="insulationCondition"
                  form={form}
                  label={t("insulationCondition")}
                  options={Object.values(InsulationCondition)}
                  error={errors.insulationCondition}
                  value={form.getValues("insulationCondition")}
                />
              </Stack>
            </FormStack>
          </Card>
          <Photos tags={tags} sphId={sphId} form={form} />
          <MainButton
            text={t("save")}
            variant="primary"
            sx={{ margin: "30px 0" }}
            onClick={handleSave}
          />
        </Form>
      </PageWrapper>
    </MainPageWrapper>
  );
};
